import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Experience } from './models/experience';
import { ExperienceManagerService } from './experience-manager.service';
import { ExperiencePage } from './models/experience-page';
import { ExperiencePanelType } from './models/experience-panel-type';
import { delay, Subscription } from 'rxjs';
import { SceneService } from "../scene.service";
import { ExperienceHotspot } from "./models/experience-hotspot";
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../../environments/environment';
import { Euler, Quaternion, Vector2 } from "three";
import { TranslateService } from '@ngx-translate/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { ExperienceNavService } from './experience-nav.service';
import { AugmentedRealityService } from './experience-augmented-reality-button/augmented-reality.service';
import TouchMoveEvent = JQuery.TouchMoveEvent;

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.sass'],
  animations: [
    trigger('fadeAnimation', [
      state('out', style({ opacity: 0 })),
      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(environment.transitionSpeed / 4)
      ]),

      transition(':leave',
        animate(environment.transitionSpeed / 4, style({ opacity: 0 })))
    ]),
    trigger('fastFadeAnimation', [
      state('out', style({ opacity: 0 })),
      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(environment.transitionSpeed / 4)
      ]),

      transition(':leave',
        animate(environment.transitionSpeed / 8, style({ opacity: 0 })))
    ]),
    trigger('delayedFadeAnimation', [
      state('out', style({ opacity: 0 })),
      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate( `${environment.transitionSpeed / 8}ms ${environment.transitionSpeed}ms`)
      ]),

      transition(':leave',
        animate(environment.transitionSpeed / 8, style({ opacity: 0 })))
    ]),
    trigger('slideDownAnimation', [

      state('out', style({ opacity: 0, position: 'relative', transform: 'translateY(-150vh)' })),

      transition(':enter', [

        style({ transform: 'translateY(-150vh)', opacity: 0, position: 'absolute' }),
        animate(`${environment.transitionSpeed / 2}ms ${environment.transitionSpeed / 4}ms`, keyframes([
          style({ transform: 'translateY(-150vh)', opacity: 0, offset: 0, position: 'absolute' }), //What a total bastard... seems the object needs time to 'appear' in the browser, removing this will cause scrollbars to jump in and out
          style({ transform: 'translateY(-150vh)', opacity: 0, offset: 0.3, position: 'relative' }),
          style({ transform: 'translateY(0px)', opacity: 1, offset: 0.66, position: 'relative' }),
          style({ transform: 'translateY(-11px)', offset: 0.68, position: 'relative' }),
          style({ transform: 'translateY(-15px)', offset: 0.72, position: 'relative' }),
          style({ transform: 'translateY(-17px)', offset: 0.75, position: 'relative' }),
          style({ transform: 'translateY(-15px)', offset: 0.78, position: 'relative' }),
          style({ transform: 'translateY(-11px)', offset: 0.81, position: 'relative' }),
          style({ transform: 'translateY(0px)', offset: 0.83, position: 'relative' }),
          style({ transform: 'translateY(-5px)', offset: 0.85, position: 'relative' }),
          style({ transform: 'translateY(-7px)', offset: 0.88, position: 'relative' }),
          style({ transform: 'translateY(-8px)', offset: 0.91, position: 'relative' }),
          style({ transform: 'translateY(-7px)', offset: 0.93, position: 'relative' }),
          style({ transform: 'translateY(-5px)', offset: 0.95, position: 'relative' }),
          style({ transform: 'translateY(0px)', offset: 0.97, position: 'relative' }),
          style({ transform: 'translateY(0px)', offset: 1, position: 'relative' }),
        ], )),
      ]),

      state('in', style({ opacity: 1, position: 'relative', transform: 'translateY(0)' })),

      transition(':leave',
        animate(environment.transitionSpeed / 4, style({ transform: 'translateY(-150vh)', position: 'relative' })))
    ])]
})
export class ExperienceComponent implements OnInit, OnDestroy {

  private currentMousePosition = new Vector2(0, 0);

  public currentLanguage: string;
  public showLoading: boolean = true;
  public supportedLanguages: string[] = environment.supportedLanguages;

  constructor(private experienceManager: ExperienceManagerService,
              private experienceNavService: ExperienceNavService,
              public sceneService: SceneService,
              public translateService: TranslateService,
              private viewPort: ViewportRuler,
              private arService: AugmentedRealityService) {
  }

  @HostListener('document:keydown', ['$event'])
  onKeypressEvent(event: KeyboardEvent) {
    if (environment.production) return;
    if (event.key === 'm') {
      this.sceneService.inSceneViewMode = !this.sceneService.inSceneViewMode;
      if (this.showUI == false && !this.sceneService.inSceneViewMode) {
        this.showUI = true;
      }
    }
    if (event.key === 'u') {
      this.showUI = !this.showUI;
    }
    if (event.key === 'h') {
      this.worldPos = `${JSON.stringify(this.sceneService.getWorldPointFromClick(this.currentMousePosition.x, this.currentMousePosition.y))}`;
    }
    if (event.key === 'j') {
      this.sceneService.camera.quaternion.copy(new Quaternion().setFromEuler(new Euler(0, 0, 0, 'XYZ')));
    }
  }

  @HostListener('document:click', ['$event'])
  onMouseClickEvent(event: MouseEvent) {
    if (environment.production) return;
    this.currentMousePosition = new Vector2(event.clientX, event.clientY);
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMoveEvent(event: MouseEvent) {
    this.experienceManager.rotateCamera(event.clientX);
  }

  @HostListener('document:touchmove', ['$event'])
  onTouchMoveEvent(event: TouchMoveEvent) {
    this.experienceManager.rotateCamera(event.targetTouches[0].clientX);
  }
  public experienceBreadcrumbs: ExperiencePage[] = [];

  public showUI: boolean = true;
  public experience: Experience;
  public parentPage?: ExperiencePage;
  public currentPage?: ExperiencePage;
  public transitioningPage?: ExperiencePage;
  public fade: boolean = false;
  public panelFade: boolean = false;
  public navbarExpanded: boolean = false;

  private experienceSub: Subscription = new Subscription();
  private updateSub: Subscription = new Subscription();
  private pageChangedSub: Subscription = new Subscription();
  private progressSub: Subscription = new Subscription();
  private langChangedSub: Subscription = new Subscription();
  private hideLoadingSub: Subscription = new Subscription();
  private windowResizedSub: Subscription = new Subscription();
  private navServiceSub: Subscription = new Subscription();
  private supportedLangs = /en|fr|de|es|it/; //TODO - add to this when adding a new language

  public worldPos: string;
  public loadProgress: number = 0;
  public isContentPanelCollapsed: boolean = false;

  switchPage(newPage: ExperiencePage): void {
    this.isContentPanelCollapsed = false;
    this.experienceManager.switchPage(newPage);
  }

  private onSwitchPage(newPage: ExperiencePage): void
  {
    this.navbarExpanded = false;
    this.currentPage.hotspots.forEach(x => x.hovering = false);
    this.fade = true;
    this.panelFade = false;
    this.transitioningPage = newPage;
    this.parentPage = this.getCurrentOrParentPageForTransition();
    this.experienceBreadcrumbs = this.buildBreadcrumbs(this.transitioningPage);

    // check whether we need to swap texture or restore default.
    this.currentPage.textureSwaps.forEach((swap) => {
      const match =  newPage.textureSwaps.find((x) => x.meshName === swap.meshName);
      if (!match) {
        // need to revert to default texture
        const defaultName = swap.textureName.endsWith('_EM') ? `${swap.meshName}_default_EM` : `${swap.meshName}_default`;
        this.sceneService.swapTextureOnMesh(defaultName);
      }
    });

    newPage.textureSwaps.forEach((swap) => {
      const match = this.currentPage.textureSwaps.find((x) => x.textureName === swap.textureName);
      if (!match) {
        this.sceneService.swapTextureOnMesh(swap.textureName);
      }
    })

    setTimeout(() => {
      this.fade = false;
      this.panelFade = true;
      this.currentPage = newPage;
    }, environment.transitionSpeed / 4);
  }


  ngOnInit(): void {
    const htmlTag = document.getElementById('root-html');
    htmlTag.className = 'v3';

    this.windowResizedSub = this.viewPort.change(50).subscribe(x => {
      this.experienceManager.onWindowResize(this.currentPage, this.transitioningPage);
    });

    this.currentLanguage = this.translateService.currentLang.match(this.supportedLangs) ?  this.translateService.currentLang : 'en';
    this.experienceSub = this.experienceManager.experience.subscribe(x => {
      this.experience = x;
      this.currentPage = this.experience.startingPage;
      this.transitioningPage = this.experience.startingPage;
      this.parentPage = this.getCurrentOrParentPageForTransition();
      this.experienceBreadcrumbs = this.buildBreadcrumbs(this.currentPage);
      this.experienceSub.unsubscribe();
      this.progressSub.unsubscribe();
    });
    this.updateSub = this.sceneService.updateEvent.subscribe(() => {
      this.experienceManager.updateHotspots(this.currentPage, this.transitioningPage);
    });
    this.pageChangedSub = this.experienceManager.pageSwitched.subscribe(x => {
      this.onSwitchPage(x);
    });
    this.progressSub = this.experienceManager.onLoadProgress.subscribe(x => {
      this.loadProgress = x;
    });
    this.langChangedSub = this.translateService.onLangChange.subscribe(x => {
      this.currentLanguage = x.lang.match(this.supportedLangs) ? x.lang : 'en';
    })
    this.hideLoadingSub = this.experienceManager.hideLoading.subscribe(() => {
      this.showLoading = false;
      this.hideLoadingSub.unsubscribe();
    });
    this.navServiceSub = this.experienceNavService.switchPage.subscribe(x => {
      this.switchPage(x);
    })
  }


  ngOnDestroy(): void {
    this.experienceSub.unsubscribe();
    this.updateSub.unsubscribe();
    this.pageChangedSub.unsubscribe();
    this.langChangedSub.unsubscribe();
    this.progressSub.unsubscribe();
    this.hideLoadingSub.unsubscribe();
    this.windowResizedSub.unsubscribe();
    this.navServiceSub.unsubscribe();
  }

  isExperienceReady(): boolean {
    return this.experience !== undefined && this.experience.startingPage !== undefined && this.currentPage !== undefined && this.experienceManager.isLoaded
  }

  isLeft(targetPage: ExperiencePage): boolean {
    return targetPage?.panelType === ExperiencePanelType.Left;
  }

  hasContent(targetPage: ExperiencePage): boolean {
    return targetPage?.panelType !== ExperiencePanelType.None &&
      (targetPage?.content !== undefined && targetPage?.content.length > 0);
  }

  isRight(targetPage: ExperiencePage): boolean {
    return targetPage?.panelType === ExperiencePanelType.Right
  }

  isCenterOrNone(targetPage: ExperiencePage): boolean {
    return targetPage?.panelType === ExperiencePanelType.None || targetPage.panelType === ExperiencePanelType.Center;
  }

  getTaglineKey(targetPage: ExperiencePage): string {
    if (targetPage === undefined) return;
    if (targetPage.useButtonNameForTagline) {
      return `${targetPage.name}`;
    }
    return `${targetPage.name}-Tagline`;
  }

  getSubTaglineKey(currentPage: ExperiencePage): string {
    return `${currentPage.name}-SubTagline`;
  }

  getHotspotStyle(hotspot: ExperienceHotspot): string {

    if (hotspot.hovering)
    {
      return `transform: translate(${hotspot.leftOffsetPercent}%, ${hotspot.topOffsetPercent}%) translate(calc(${hotspot.position2D.x}px + 0.5rem), ${hotspot.position2D.y}px); width: calc(${hotspot.width}px + 2.5rem)`;
    }
    return `transform: translate(${hotspot.leftOffsetPercent}%, ${hotspot.topOffsetPercent}%) translate(calc(${hotspot.position2D.x}px + 0.5rem), ${hotspot.position2D.y}px);`;
  }

  getSceneViewCameraPosition(): string {
    return `${JSON.stringify(this.sceneService.getCameraPosition())}`;
  }

  getSceneViewCameraRotation(): string {
    return `${JSON.stringify(this.sceneService.getCameraRotation().toVector3())}`;
  }

  isPageRelatedToCurrent(page: ExperiencePage, targetPage: ExperiencePage): boolean {
    if (targetPage === undefined) return false;
    if (page == targetPage) return true;
    if (page.children.length < 0) return false;
    return page.children.some(p => this.isPageRelatedToCurrent(p, targetPage));
  }

  buildBreadcrumbs(targetPage: ExperiencePage): ExperiencePage[] {
    let breadCrumbs: ExperiencePage[] = [];
    let currentTarget = this.experience.startingPage;
    breadCrumbs.push(currentTarget);

    while (currentTarget !== undefined) {
      if (currentTarget.children.length > 0) {
        let currentRelatedPages = currentTarget.children.filter(x => this.isPageRelatedToCurrent(x, targetPage));
        if (currentRelatedPages.length > 0) {
          currentTarget = currentRelatedPages[0];
          breadCrumbs.push(currentTarget);
        } else {
          currentTarget = undefined;
        }
      } else {
        currentTarget = undefined;
      }
    }

    breadCrumbs.pop();
    return breadCrumbs;
  }

  getCurrentOrParentPageForTransition(): ExperiencePage {
    if (this.transitioningPage.children.length > 0) return this.transitioningPage;
    return this.getParentPage(this.transitioningPage);
  }

  getCurrentOrParentPage(): ExperiencePage {
    if (this.currentPage.children.length > 0) return this.currentPage;
    return this.getParentPage(this.currentPage);
  }

  getParentPage(currentPage: ExperiencePage): ExperiencePage {
    return this.getParentSubPage(this.experience.startingPage, currentPage);
  }

  getParentSubPage(targetPage: ExperiencePage, currentPage: ExperiencePage): ExperiencePage {
    if (targetPage === currentPage) return targetPage;
    if (targetPage.children.length <= 0) return undefined;
    if (targetPage.children.some(y => y === currentPage)) return targetPage;

    let subPage: ExperiencePage = undefined;
    targetPage.children.forEach(y => {
      if (subPage !== undefined) return;
      subPage = this.getParentSubPage(y, currentPage);
    });
    return subPage;
  }

  switchPageByName(name: string): void {
    this.experienceManager.switchPageByName(name);
  }

  switchPreviousPage(): void {
    let index = this.parentPage.children.indexOf(this.currentPage);
    if (index === -1) {
      return;
    }
    index--;
    if (index < 0) {
      index = this.parentPage.children.length - 1;
    }
    let page = this.parentPage.children[index];
    this.switchPage(page);
  }

  switchNextPage(): void {
    let index = this.parentPage.children.indexOf(this.currentPage);
    if (index === -1) {
      return;
    }
    index++;
    if (index >= this.parentPage.children.length) {
      index = 0;
    }
    let page = this.parentPage.children[index];
    this.switchPage(page);
  }


  changeLanguage(supportedLanguage: string): void {
    this.translateService.use(supportedLanguage);
  }

  getBigHeaderKey(transitioningPage: ExperiencePage): string {
    return `${transitioningPage.name}-BigHeader`;
  }

  onMouseOverHotspot(hotspot: ExperienceHotspot, hotspotHoverText: HTMLDivElement): void {
    hotspot.width = hotspotHoverText.offsetWidth;
    hotspot.hovering = true;
  }

  onMouseLeaveHotspot(hotspot: ExperienceHotspot): void {
    hotspot.hovering = false;
  }

  toggleNavbar(): void {
    this.navbarExpanded = !this.navbarExpanded;
  }

  toggleContent(): void {
    this.isContentPanelCollapsed = !this.isContentPanelCollapsed;
  }

  async showAr(): Promise<void> {
    await this.arService.showAr(this.experience?.arUrl);
  }
}
