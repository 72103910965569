import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormEndpointService } from '../forms/form-endpoint.service';

@Component({
  selector: 'app-xt640s',
  templateUrl: './xt640s.component.html',
  styleUrls: ['./xt640s.component.sass']
})
export class Xt640sComponent implements AfterViewInit {

  @ViewChild('consultation') consultation: ElementRef;
  @ViewChild('brochure') brochure: ElementRef;
  constructor(private modalService: NgbModal) { }

  ngAfterViewInit(): void {
    (window as any).openBrochureForm = () => this.openBrochure();
    (window as any).openMainForm = () => this.openConsultation();
  }

  private openBrochure() {
    this.modalService.open(this.brochure, {ariaLabelledBy: 'brochure-title', size: 'lg', scrollable: true});
  }

  private openConsultation() {
    this.modalService.open(this.consultation, {ariaLabelledBy: 'consultation-title', size: 'lg', scrollable: true});
  }
}
