import { RouterModule } from '@angular/router';
import { Vg3Component } from './experiences/vg3/vg3.component';
import { Sg3Component } from './experiences/sg3/sg3.component';
import { AugmentedRealityComponent } from './shared/augmented-reality/augmented-reality.component';


const routes = [
    {
      path: 'v3',
      children: [
        {
          path: 'vg3',
          component: Vg3Component
        },
        {
          path: 'vg3/ar',
          component: AugmentedRealityComponent,
          data: {
            iosModel: '/assets/v3/models/ar/VG3-640-scaled-AR.usdz',
            androidModel: '/assets/v3/models/ar/VG3-640-scaled-AR.glb',
            returnUrl: '/v3/vg3'
          }
        },
        {
          path: 'sg3',
          component: Sg3Component
        }, {
          path: 'sg3/ar',
          component: AugmentedRealityComponent,
          data: {
            iosModel: '/assets/v3/models/ar/SG3-540-scaled-AR.usdz',
            androidModel: '/assets/v3/models/ar/SG3-540-scaled-AR.glb',
            returnUrl: '/v3/sg3'
          }
        }
      ]
    }
  ]
;
export const v3RoutingModule = RouterModule.forChild(routes);
