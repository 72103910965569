import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { v3RoutingModule } from './v3-routing.module';
import { Vg3Component } from './experiences/vg3/vg3.component';
import { Sg3Component } from './experiences/sg3/sg3.component';
import { SharedModule } from '../shared/shared.module';
import { ExperienceComponent } from './shared/experience/experience.component';
import { ExperienceContentComponent } from './shared/experience/experience-content/experience-content.component';
import { ExperienceLoadingComponent } from './shared/experience/experience-loading/experience-loading.component';
import { AugmentedRealityComponent } from './shared/augmented-reality/augmented-reality.component';
import { ExperienceAugmentedRealityButtonComponent } from './shared/experience/experience-augmented-reality-button/experience-augmented-reality-button.component';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ExperienceNavItemComponent } from './shared/experience/experience-nav-item/experience-nav-item.component';

@NgModule({
  declarations: [
    Vg3Component,
    Sg3Component,
    ExperienceComponent,
    ExperienceContentComponent,
    ExperienceLoadingComponent,
    AugmentedRealityComponent,
    ExperienceAugmentedRealityButtonComponent,
    ExperienceNavItemComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        v3RoutingModule,
        NgbCollapseModule,
        NgbDropdownModule
    ]
})
export class V3Module { }
