import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ExperienceManagerService } from '../../shared/experience/experience-manager.service';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { Experience } from '../../shared/experience/models/experience';
import * as experience from '../../../../assets/v3/experiences/sg3.json';

@Component({
  selector: 'app-sg3',
  templateUrl: './sg3.component.html',
  styleUrls: ['./sg3.component.sass']
})
export class Sg3Component implements AfterViewInit {

  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(private experienceService: ExperienceManagerService) { }

  private subscription: Subscription = new Subscription();

  async ngAfterViewInit(): Promise<void> {
    let experienceCast = plainToInstance(Experience, experience);
    await this.experienceService.loadExperience(experienceCast, this.video.nativeElement);
  }

  ngOnDestroy(): void {
    this.experienceService.onDestroy();
    this.subscription?.unsubscribe();
  }


}
