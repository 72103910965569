import { ExperienceContentType } from './experience-content-type';
import { Type } from 'class-transformer';
import { Guid } from 'guid-typescript';

export class ExperienceContent {


  constructor(public contentType: ExperienceContentType) {
  }

  public textKey: string;
  public className: string;
  public imagePath: string;

  @Type(() => ExperienceContent)
  public children: ExperienceContent[] = [];

  public onClickGoToPage: string | undefined;

  public isExpanded: boolean = false;

  public linkKey: string;
}
