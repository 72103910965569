import { Component, Input, OnInit } from '@angular/core';
import { ExperiencePage } from '../models/experience-page';
import { ExperienceNavService } from '../experience-nav.service';

@Component({
  selector: 'app-experience-nav-item',
  templateUrl: './experience-nav-item.component.html',
  styleUrls: ['./experience-nav-item.component.sass']
})
export class ExperienceNavItemComponent implements OnInit {

  @Input() public currentPage!: ExperiencePage;
  @Input() public page!: ExperiencePage;
  @Input() public isFirst: boolean = false;
  @Input() public level: number = 0;
  public levelClass: string;

  constructor(public experienceNavService: ExperienceNavService) { }

  ngOnInit(): void {
    this.levelClass = `level-${this.level}`;
  }

  switchPage(page: ExperiencePage): void {
    this.experienceNavService.switchPage.next(page);
  }
}
