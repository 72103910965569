import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-augmented-reality',
  templateUrl: './augmented-reality.component.html',
  styleUrls: ['./augmented-reality.component.sass']
})
export class AugmentedRealityComponent implements AfterViewInit {

  @ViewChild('iosARLink') public iosARLink: ElementRef<HTMLAnchorElement>;
  @ViewChild('androidARLink') public androidARLink: ElementRef<HTMLAnchorElement>;

  constructor(private renderer: Renderer2,
              private sanitizer: DomSanitizer,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  public ngAfterViewInit(): void {
    this.openAr();
  }

  public getRootDomain(): string {
    return window.location.origin;
  }

  public getIoSModelUrl(): string {
    return `${this.getRootDomain()}${this.activatedRoute.snapshot.data.iosModel}#allowsContentScaling=0#canonicalWebPageURL=${window.location.href.replace('/ar', '')}`;
  }

  public getAndroidModelIntentUrl(): SafeResourceUrl {
    const fileUrl = `${this.getRootDomain()}${this.activatedRoute.snapshot.data.androidModel}`
    const fallbackUrl = `https://arvr.google.com/scene-viewer?file=${encodeURIComponent(fileUrl)}&mode=ar_only&resizable=false&link=${window.location.href.replace('/ar', '')}`
    return this.sanitizer.bypassSecurityTrustResourceUrl(`intent://arvr.google.com/scene-viewer/1.0?file=${fileUrl}&mode=ar_only&resizable=false&link=${window.location.href.replace('/ar', '')}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${fallbackUrl};end;`);
  }

  public async openAr(): Promise<void> {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      if (this.iosARLink.nativeElement.relList.supports('ar')) {
        this.iosARLink.nativeElement.click();
      } else {
        this.androidARLink.nativeElement.click();
      }
    }
    else {
      console.warn('Your device is not supported for AR');
      await this.router.navigateByUrl(this.activatedRoute.snapshot.data.returnUrl);
    }
  }
}
