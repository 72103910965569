import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AugmentedRealityService {

  constructor(private router: Router) {
  }

  async showAr(arUrl: string): Promise<boolean> {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && arUrl !== undefined && arUrl !== null && arUrl !== '') {
      await this.router.navigateByUrl(arUrl);
      return true;
    } else {
      return false;
    }
  }
}
