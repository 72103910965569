import { Vector3 } from 'three';
import {ExperienceTextureSwapData} from "./experience-texture-swap-data";

export class ExperienceModel {
  constructor(
    public name: string,
    public path: string,
    public position: Vector3,
    public rotation: Vector3,
    public scale: Vector3,
    public castShadows: boolean,
    public receiveShadows: boolean
  ) {
  }

  public textureSwapData: ExperienceTextureSwapData[];
}
