import { Injectable } from '@angular/core';
import { Dimension } from './dimension';

@Injectable({
  providedIn: 'root'
})
export class DimensionsService {

  constructor() { }

  public getWindowDimensions(): Dimension {
    const useScreen = window.screen.width < window.innerWidth;
    if (useScreen) {
      return new Dimension(window.screen.width, window.screen.height);
    }
    return new Dimension(window.innerWidth, window.innerHeight);
  }
}
