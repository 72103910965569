import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

declare var createUnityInstance: any;

@Component({
  selector: 'app-unity-loader',
  templateUrl: './unity-loader.component.html',
  styleUrls: ['./unity-loader.component.sass']
})
export class UnityLoaderComponent implements OnInit, AfterViewInit {

  @Input('iosARModel') public iosArModel: string;
  @Input('androidARModel') public androidARModel: string;
  @Input('modelName') public modelName: string;
  @Input('sceneName') public sceneName: string;
  @ViewChild('loadingCover') public loadingCover: ElementRef<HTMLDivElement>;
  @ViewChild('iosARLink') public iosARLink: ElementRef<HTMLAnchorElement>;
  @ViewChild('androidARLink') public androidARLink: ElementRef<HTMLAnchorElement>;

  private gameObjectName: string = 'ExternalSettingsHandler'
  private startingY: number;



  constructor(private activatedRoute: ActivatedRoute,
              private translateService: TranslateService,
              private httpClient: HttpClient,
              private renderer: Renderer2,
              private sanitizer: DomSanitizer) {
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const canvas = document.querySelector("#unity-canvas") as HTMLCanvasElement;
    this.resizeUnity(canvas);
  }

  ngOnInit(): void {
    const htmlTag = document.getElementById('root-html');
    htmlTag.className = 'v2';

    if (this.activatedRoute.snapshot.data.ar === true) {
      return;
    }

    (window as any).sendLoadProgress = (progress: number) => this.sendLoadProgress(progress);
    (window as any).unityLoadComplete = () => this.unityLoadComplete();
    (window as any).openAr = () => this.openAr();
    (window as any).redirectToProductPage = () => this.redirectToProductPage();
    //Fetch the config, then load
    this.httpClient.get("/api/v1/configuration").subscribe((result: any) => {
      const hideFullScreenButton = "TRUE";
      const buildUrl = `${result.blobStorageLocation}/unity`;
      const loaderUrl = buildUrl + "/roland-unity-web-experience.loader.js";
      const config = {
        dataUrl: buildUrl + "/roland-unity-web-experience.data.unityweb",
        frameworkUrl: buildUrl + "/roland-unity-web-experience.framework.js.unityweb",
        codeUrl: buildUrl + "/roland-unity-web-experience.wasm.unityweb",
        streamingAssetsUrl: buildUrl + "/StreamingAssets",
        companyName: "Phygital Ltd",
        productName: "Roland Web Experience",
        productVersion: "0.6.4",
        devicePixelRatio: undefined
      };

      const container = document.querySelector("#unity-container") as HTMLDivElement;
      const canvas = document.querySelector("#unity-canvas") as HTMLCanvasElement;
      const fullscreenButton = document.querySelector("#unity-fullscreen-button") as HTMLButtonElement;

      const canFullscreen = (function () {
        for (const key of [
          'exitFullscreen',
          'webkitExitFullscreen',
          'webkitCancelFullScreen',
          'mozCancelFullScreen',
          'msExitFullscreen',
        ]) {
          if (key in document) {
            return true;
          }
        }
        return false;
      }());

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        container.className = "unity-mobile";
        config.devicePixelRatio = 1;
      }

      const script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        const fill = document.querySelector(".fill") as SVGRectElement;
        const fillHeight = fill.height.baseVal.value;
        this.startingY = fill.y.baseVal.value;
        if (this.startingY == 0) this.startingY = 58;
        this.startingY = this.startingY + fillHeight;
        createUnityInstance(canvas, config, (progress) => {
          console.log(progress);

          if (progress === 1) {
            this.resizeUnity(canvas);
            const newY = this.startingY - (fillHeight * (progress * 0.25));
            fill.setAttribute("y", newY.toString());
          } else {
            const newY = this.startingY - (fillHeight * (progress * 0.25));
            fill.setAttribute("y", newY.toString());
          }

        }).then((unityInstance) => {

          if (canFullscreen) {
            if (!hideFullScreenButton) {
              fullscreenButton.style.display = "";
            }
            fullscreenButton.onclick = () => {
              unityInstance.SetFullscreen(1);
            };
          }

          let lang = this.translateService.currentLang;
          if (lang.length > 2) lang = lang.substring(0, 2);

          const isMobile = (/Mobi|Android/i.test(navigator.userAgent));
          console.log(`Sending Message ImportCultureCode ${lang}`);
          unityInstance.SendMessage(this.gameObjectName, 'ImportCultureCode', lang);
          console.log(`Sending Message ImportMobileStatus ${isMobile}`);
          unityInstance.SendMessage(this.gameObjectName, 'ImportMobileStatus', isMobile.toString());
          console.log(`Sending Message ImportExperienceName ${this.sceneName}`);
          unityInstance.SendMessage(this.gameObjectName, 'ImportExperienceName', this.sceneName);

          const arUrl = encodeURIComponent(`${window.location}/ar`);
          const importArUrl = `${this.getRootDomain()}/api/v1.0/qrcode?url=${arUrl}`;
          console.log(`Sending Message ImportARUrl ${importArUrl}`);

          unityInstance.SendMessage(this.gameObjectName, 'ImportARUrl', importArUrl);

        }).catch((message) => {
          alert(message);
        });
      };
      document.body.appendChild(script);
    })
  }

  ngAfterViewInit(): void {
    if (this.activatedRoute.snapshot.data.ar === true) {
      this.openAr();
      if (/Android/i.test(navigator.userAgent)) {
        this.renderer.addClass(this.loadingCover.nativeElement, 'hide');
        const canvas = document.querySelector("#unity-canvas") as HTMLCanvasElement;
        this.renderer.addClass(canvas, 'hide');
      }


    }
  }


  private sendLoadProgress(progress: number): void {
    console.log(progress);
    const fill = document.querySelector(".fill") as SVGRectElement;
    const fillHeight = fill.height.baseVal.value;
    const newY = this.startingY - (fillHeight * 0.25) - (fillHeight * (progress * 0.75));
    fill.setAttribute("y", newY.toString());
  }

  private unityLoadComplete(): void {
    console.log('done!');
    this.renderer.addClass(this.loadingCover.nativeElement, 'hide');
    const fill = document.querySelector(".fill") as SVGRectElement;
    const fillHeight = fill.height.baseVal.value;
    const newY = this.startingY - fillHeight;
    fill.setAttribute("y", newY.toString());
  }


  private resizeUnity(canvas: HTMLCanvasElement): void {
    let w = (window as any);
    if (w.visualViewport !== undefined) {
      canvas.width = w.visualViewport.width;
      canvas.height = w.visualViewport.height;
      console.log(`resized VVP ${canvas.width} ${canvas.height}`);
    } else {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      console.log(`resized ${canvas.width} ${canvas.height}`);
    }
  }

  private redirectToProductPage(): void {
    this.translateService.get(`${this.sceneName} Product Page`).subscribe(y =>
    {
      window.top.location.href = y;
    })
  }

  public getRootDomain(): string {
    return window.location.origin;
  }

  public getIoSModelUrl(): string {
    return `${this.getRootDomain()}/assets/models/${this.iosArModel}#allowsContentScaling=0#canonicalWebPageURL=${window.location.href.replace('/ar', '')}`;
  }

  public getAndroidModelIntentUrl(): SafeResourceUrl {
    const fileUrl = `${this.getRootDomain()}/assets/models/${this.androidARModel}`
    const fallbackUrl = `https://arvr.google.com/scene-viewer?file=${encodeURIComponent(fileUrl)}&mode=ar_only&resizable=false&link=${window.location.href.replace('/ar', '')}`
    return this.sanitizer.bypassSecurityTrustResourceUrl(`intent://arvr.google.com/scene-viewer/1.0?file=${fileUrl}&mode=ar_only&resizable=false&link=${window.location.href.replace('/ar', '')}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${fallbackUrl};end;`);
    //return `intent://arvr.google.com/scene-viewer/1.0?file=${fileUrl}&mode=ar_only&title=${this.modelName}&resizable=false#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
  }

  public openAr() {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      if (this.iosARLink.nativeElement.relList.supports('ar')) {
        this.iosARLink.nativeElement.click();
      } else {
        this.androidARLink.nativeElement.click();
      }
    }
  }
}
