<div class="loading-overlay d-flex flex-column justify-content-start align-items-start">
  <div class="loading-bar mt-3" [style.width.%]="percentLoaded"></div>
  <div class="loading-background my-5 h-100 w-100 d-flex flex-lg-landscape-row flex-lg-portrait-row flex-column justify-content-start align-items-center {{this.cssClass}}">
    <div class="loading-content w-100 w-lg-50 ms-lg-5 me-lg-0 px-3 h-100 d-flex flex-column justify-content-lg-center justify-content-start align-items-start" *ngIf="name !== undefined">
      <img class="mb-3 me-3" src="{{this.loadingBannerImage}}" alt="{{this.headerLoadingText | translate}}"/>
      <h2>{{this.subHeaderLoadingText | translate}}</h2>
      <div class="button-container w-100 mt-3 mt-lg-5 d-flex flex-row justify-content-start">
        <button class="btn" [ngClass]="{ 'hidden': percentLoaded < 100 }" (click)="hide()">{{'Begin' | translate}}</button>
      </div>
    </div>
    <div class="loading-background-mobile d-block d-lg-none mx-3 {{this.cssClass}} w-100 h-100">

    </div>
  </div>
</div>
<div class="fixed-bottom d-flex justify-content-start phygital-link mb-3 ms-3 ms-lg-5 mb-lg-5">
  <a href="https://phygital.co.uk/" target="_blank">Created by Phygital</a>
</div>
