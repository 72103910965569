import {Mesh, Texture} from "three";

export class TextureData {
  constructor(
    public name: string,
    public texture: Texture,
    public targetMesh: Mesh,
    public isVideo: boolean = false
  ) {
  }

  public videoUrl: string;
}
