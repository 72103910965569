import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormEndpointService } from '../form-endpoint.service';

@Component({
  selector: 'app-lec2-s-series-brochure-form',
  templateUrl: './lec2-s-series-brochure-form.component.html',
  styleUrls: ['./lec2-s-series-brochure-form.component.sass']
})
export class Lec2SSeriesBrochureFormComponent implements OnInit {

  @ViewChild('printerModel') printerModel: ElementRef<HTMLSelectElement>


  constructor(private formEndPointService: FormEndpointService) {
  }

  ngOnInit(): void {
    this.formEndPointService.formEndpoint.next('https://go.rolanddg.eu/l/181922/2021-05-28/rrr7b');
  }
}
