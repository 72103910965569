import { Injectable } from '@angular/core';
import { LoadingManager, Texture, TextureLoader } from "three";

@Injectable({
  providedIn: 'root'
})
export class TextureLoaderService {

  constructor() {
  }

  public async loadTexture(path: string, loadingManager: LoadingManager): Promise<Texture> {
    const loader = new TextureLoader(loadingManager);
    return await loader.loadAsync(path);
  }
}
