import {Vector2, Vector3} from 'three';
import {Type} from "class-transformer";

export class ExperienceHotspot {


  constructor(
    public className: string,
    public hoverTextKey: string,
    public onClickGoToPage: string,
    public topOffsetPercent: number = -50,
    public leftOffsetPercent: number = -50
  ) {
  }

  @Type(() => Vector3)
  public position!: Vector3;

  public visibleOnMobile: boolean = false;

  //Not for JSON use
  @Type(() => Vector2)
  public position2D: Vector2 = new Vector2(0, 0);

  //Not for JSON use
  public width: number = 0;

  //Not for JSON use
  public hovering: boolean = false;
}
