import { RouterModule } from '@angular/router';
import { TruevisVg2Component } from './truevis-vg2/truevis-vg2.component';
import { SuccessComponent } from './forms/success/success.component';
import { LefBrochureFormComponent } from './forms/lef-brochure-form/lef-brochure-form.component';
import { LefConsultantFormComponent } from './forms/lef-consultant-form/lef-consultant-form.component';
import { Iu1000fComponent } from './iu-1000f/iu1000f.component';
import { Lec2640Component } from './lec2-640/lec2640.component';
import { Lec2SSeriesComponent } from './lec2-s-series/lec2-s-series.component';
import { Sg2Component } from './sg2/sg2.component';
import { Vf2640Component } from './vf2-640/vf2640.component';
import { Xt640sComponent } from './xt-640s/xt640s.component';
import { LefComponent } from './lef2-300/lef2300.component';
import { Vg2BrochureFormComponent } from './forms/vg2-brochure-form/vg2-brochure-form.component';
import { Vg2ConsultantFormComponent } from './forms/vg2-consultant-form/vg2-consultant-form.component';
import { Vf2BrochureFormComponent } from './forms/vf2-brochure-form/vf2-brochure-form.component';
import { Vf2ConsultantFormComponent } from './forms/vf2-consultant-form/vf2-consultant-form.component';
import { XtConsultantFormComponent } from './forms/xt-consultant-form/xt-consultant-form.component';
import { XtBrochureFormComponent } from './forms/xt-brochure-form/xt-brochure-form.component';
import { Sg2BrochureFormComponent } from './forms/sg2-brochure-form/sg2-brochure-form.component';
import { Sg2ConsultantFormComponent } from './forms/sg2-consultant-form/sg2-consultant-form.component';
import { Lec2BrochureFormComponent } from './forms/lec2-brochure-form/lec2-brochure-form.component';
import { Lec2ConsultantFormComponent } from './forms/lec2-consultant-form/lec2-consultant-form.component';
import { IuBrochureFormComponent } from './forms/iu-brochure-form/iu-brochure-form.component';
import { IuConsultantFormComponent } from './forms/iu-consultant-form/iu-consultant-form.component';
import { Lec2SSeriesConsultantFormComponent } from './forms/lec2-s-series-consultant-form/lec2-s-series-consultant-form.component';
import { Lec2SSeriesBrochureFormComponent } from './forms/lec2-s-series-brochure-form/lec2-s-series-brochure-form.component';


const routes = [
    {
      path: 'v2/iu-1000f',
      component: Iu1000fComponent
    },
    {
      path: 'v2/iu-1000f/ar',
      component: Iu1000fComponent,
      data: { ar: true }
    },
    {
      path: 'v2/lec2',
      component: Lec2640Component
    },
    {
      path: 'v2/lec2/ar',
      component: Lec2640Component,
      data: { ar: true }
    },
    {
      path: 'v2/lec2-s-series',
      component: Lec2SSeriesComponent
    },
    {
      path: 'v2/lec2-s-series/ar',
      component: Lec2SSeriesComponent,
      data: { ar: true }
    },
  {
    path: 'v2/versa-object-co-300',
    component: Lec2SSeriesComponent
  },
  {
    path: 'v2/versa-object-co-300/ar',
    component: Lec2SSeriesComponent,
    data: { ar: true }
  },
    {
      path: 'v2/lef2',
      component: LefComponent,
    },
    {
      path: 'v2/lef2/ar',
      component: LefComponent,
      data: { ar: true }
    },
    {
      path: 'v2/sg2',
      component: Sg2Component
    },
    {
      path: 'v2/sg2/ar',
      component: Sg2Component,
      data: { ar: true }
    },
    {
      path: 'v2/truevis-vg2',
      component: TruevisVg2Component,
    },
    {
      path: 'v2/truevis-vg2/ar',
      component: TruevisVg2Component,
      data: { ar: true }
    },
    {
      path: 'v2/vf2',
      component: Vf2640Component,
    },
    {
      path: 'v2/vf2/ar',
      component: Vf2640Component,
      data: { ar: true }
    },
    {
      path: 'v2/xt-640s',
      component: Xt640sComponent,
    },
    {
      path: 'v2/xt-640s/ar',
      component: Xt640sComponent,
      data: { ar: true }
    },
    {
      path: 'v2/lef2/brochure',
      component: LefBrochureFormComponent
    },
    {
      path: 'v2/lef2/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/lef2/consultation',
      component: LefConsultantFormComponent
    },
    {
      path: 'v2/lef2/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/xt-640s/brochure',
      component: XtBrochureFormComponent
    },
    {
      path: 'v2/xt-640s/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/xt-640s/consultation',
      component: XtConsultantFormComponent
    },
    {
      path: 'v2/xt-640s/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/vf2/brochure',
      component: Vf2BrochureFormComponent
    },
    {
      path: 'v2/vf2/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/vf2/consultation',
      component: Vf2ConsultantFormComponent
    },
    {
      path: 'v2/vf2/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/truevis-vg2/brochure',
      component: Vg2BrochureFormComponent
    },
    {
      path: 'v2/truevis-vg2/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/truevis-vg2/consultation',
      component: Vg2ConsultantFormComponent
    },
    {
      path: 'v2/truevis-vg2/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/sg2/brochure',
      component: Sg2BrochureFormComponent
    },
    {
      path: 'v2/sg2/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/sg2/consultation',
      component: Sg2ConsultantFormComponent
    },
    {
      path: 'v2/sg2/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/lec2-s-series/brochure',
      component: Lec2SSeriesBrochureFormComponent
    },
    {
      path: 'v2/lec2-s-series/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/lec2-s-series/consultation',
      component: Lec2SSeriesConsultantFormComponent
    },
    {
      path: 'v2/lec2-s-series/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/versa-object-co-300/brochure',
      component: Lec2SSeriesBrochureFormComponent
    },
    {
      path: 'v2/versa-object-co-300/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/versa-object-co-300/consultation',
      component: Lec2SSeriesConsultantFormComponent
    },
    {
      path: 'v2/lversa-object-co-300/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/lec2/brochure',
      component: Lec2BrochureFormComponent
    },
    {
      path: 'v2/lec2/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/lec2/consultation',
      component: Lec2ConsultantFormComponent
    },
    {
      path: 'v2/lec2/consultation/success',
      component: SuccessComponent
    },
    {
      path: 'v2/iu-1000f/brochure',
      component: IuBrochureFormComponent
    },
    {
      path: 'v2/iu-1000f/brochure/success',
      component: SuccessComponent
    },
    {
      path: 'v2/iu-1000f/consultation',
      component: IuConsultantFormComponent
    },
    {
      path: 'v2/iu-1000f/consultation/success',
      component: SuccessComponent
    }
  ]
;
export const v2TruevisVg2RoutingModule = RouterModule.forChild(routes);
