import { AfterContentChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormEndpointService } from '../form-endpoint.service';

@Component({
  selector: 'app-vg2-consultant-form',
  templateUrl: './vg2-consultant-form.component.html',
  styleUrls: ['./vg2-consultant-form.component.sass']
})
export class Vg2ConsultantFormComponent implements OnInit {

  @ViewChild('printerModel') printerModel: ElementRef<HTMLSelectElement>

  constructor(private formEndPointService: FormEndpointService) {
  }

  ngOnInit(): void {
    this.formEndPointService.formEndpoint.next('https://go.rolanddg.eu/l/181922/2021-06-01/rs888');
  }
}
