<app-unity-loader [sceneName]="'SG2_Scene'"
                  [androidARModel]="'sg2/sg2.glb'"
                  [iosARModel]="'sg2/sg2.usdz'"
                  [modelName]="'SG2'"></app-unity-loader>
<ng-template #brochure let-brochureModal>
  <div class="modal-header">
    <h4 class="modal-title" id="brochure-title">{{'Request Brochure' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="brochureModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="justify-content-center align-items-center w-100 lds-container" #brochureLoading>
      <div class="lds-dual-ring"></div>
    </div>
    <div class="iframe-container">
      <iframe src="/v2/sg2/brochure" loading="eager" scrolling="auto" (load)="brochureLoading.style.display = 'none'"></iframe>
    </div>
  </div>
</ng-template>
<ng-template #consultation let-consultationModal>
  <div class="modal-header">
    <h4 class="modal-title" id="consultation-title">{{'Request Consultation' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="consultationModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="justify-content-center align-items-center w-100 lds-container" #consultationLoading>
      <div class="lds-dual-ring"></div>
    </div>
    <div class="iframe-container">
      <iframe src="/v2/sg2/consultation" loading="eager" scrolling="auto" (load)="consultationLoading.style.display = 'none'"></iframe>
    </div>
  </div>
</ng-template>
