import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ExperiencePage } from './models/experience-page';

@Injectable({
  providedIn: 'root'
})
export class ExperienceNavService {

  public switchPage: Subject<ExperiencePage> = new Subject<ExperiencePage>();

  constructor() { }

}
