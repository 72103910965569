import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lef',
  templateUrl: './lef2300.component.html',
  styleUrls: ['./lef2300.component.sass']
})
export class LefComponent implements AfterViewInit {

  @ViewChild('consultation') consultation: ElementRef;
  @ViewChild('brochure') brochure: ElementRef;
  constructor(private modalService: NgbModal) { }

  ngAfterViewInit(): void {
    (window as any).openBrochureForm = () => this.openBrochure();
    (window as any).openMainForm = () => this.openConsultation();
  }

  private openBrochure() {
    this.modalService.open(this.brochure, {ariaLabelledBy: 'brochure-title', size: 'lg', scrollable: true});
  }

  private openConsultation() {
    this.modalService.open(this.consultation, {ariaLabelledBy: 'consultation-title', size: 'lg', scrollable: true});
  }
}
