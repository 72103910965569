<form #form [action]="formEndpointService.formEndpoint | async" method="post" [formGroup]="brochureForm">
  <ng-content></ng-content>
  <div class="form-group">
    <label>{{'First Name' | translate}}</label>
    <input type="text" id="firstName" name="First Name" class="form-control" required="required" formControlName="firstName" />
    <p class="text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors.required">{{'First Name is required' | translate}}</p>
  </div>
  <div class="form-group">
    <label>{{'Last Name' | translate}}</label>
    <input type="text" name="Last Name" class="form-control" required="required" formControlName="lastName" />
    <p class="text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors.required">{{'Last Name is required' | translate}}</p>
  </div>
  <div class="form-group">
    <label>{{'Email Address' | translate}}</label>
    <input type="email" name="Email Address" class="form-control" required="required" formControlName="email"/>
    <p class="text-danger" *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required">{{'Email is required' | translate}}</p>
    <p class="text-danger" *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">{{'Email must be a valid email address' | translate}}</p>
  </div>
  <div class="form-group">
    <label>{{'Company' | translate}}</label>
    <input type="text" name="Company" class="form-control" required="required" formControlName="company" />
    <p class="text-danger" *ngIf="company.invalid && (company.dirty || company.touched) && company.errors.required">{{'Company is required' | translate}}</p>
  </div>
  <div class="form-group">
    <label>{{'Country' | translate}}</label>
    <app-country-select #countrySelect [ngClass]="{'p-0 col-6': true }"></app-country-select>
    <input type="hidden" name="Country" required="required" [value]="getCountry(countrySelect.country)" formControlName="country" />
    <p class="text-danger" *ngIf="country.invalid && (country.dirty || country.touched) && country.errors.required">{{'Country is required' | translate}}</p>
  </div>
  <input type="hidden" name="source" value="Virtual Product" />
  <div class="form-group d-flex justify-content-center align-items-center w-100">
    <input type="button" id="btnSubmit" name="btnSubmit" class="btn btn-primary" (click)="submit()" value="{{'Send Request' | translate}}" />
  </div>
</form>
