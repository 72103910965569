import { Component, Input } from '@angular/core';
import { ExperienceContent } from '../models/experience-content';
import { ExperienceContentType } from '../models/experience-content-type';
import { ExperiencePage } from '../models/experience-page';
import { ExperienceManagerService } from '../experience-manager.service';

@Component({
  selector: 'app-experience-content',
  templateUrl: './experience-content.component.html',
  styleUrls: ['./experience-content.component.sass']
})
export class ExperienceContentComponent {

  public contentType: typeof ExperienceContentType = ExperienceContentType;

  @Input() public currentPage: ExperiencePage;
  @Input() public content!: ExperienceContent;

  constructor(private experienceManager: ExperienceManagerService) { }

  switchPage(newPage: ExperiencePage): void {
    this.experienceManager.switchPage(newPage);
  }

  switchPageByName(name: string): void {
    if (name === undefined || name === null || name === '') return;
    this.experienceManager.switchPageByName(name);
  }

  toggleAccordion(content: ExperienceContent, child: ExperienceContent) {
    content.children.forEach(x => {
      if (x === child)
      {
        x.isExpanded = !x.isExpanded;
      }
      else
      {
        x.isExpanded = false;
      }
    })
  }
}
