import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.sass']
})
export class LocalizationComponent {

  selectedLanguage: string;
  languages: string[] = [
    'en',
    'fr',
    'de'
  ];
  private translateService: TranslateService;

  selectedLanguageChanged(value: string): void {
    console.log(value);
    this.selectedLanguage = value;
    this.translateService.use(value);
  }

  constructor(translateService: TranslateService) {
    this.translateService = translateService;
    this.selectedLanguage = this.translateService.currentLang;
  }
}

