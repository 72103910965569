import { Injectable } from '@angular/core';
import { FileLoader, LoadingManager, Texture, TextureLoader } from 'three';

@Injectable({
  providedIn: 'root'
})
export class FileLoaderService {

  constructor() { }

  public async loadFile(path: string, loadingManager: LoadingManager): Promise<string | ArrayBuffer> {
    const loader = new FileLoader(loadingManager);
    loader.setResponseType('arraybuffer');
    return await loader.loadAsync(path);
  }
}
