import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormEndpointService } from '../form-endpoint.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

const countries = require("i18n-iso-countries");

@Component({
  selector: 'app-default-brochure-form',
  templateUrl: './default-brochure-form.component.html',
  styleUrls: ['./default-brochure-form.component.sass']
})
export class DefaultBrochureFormComponent implements OnInit, AfterViewInit {


  public brochureForm: FormGroup;

  @ViewChild('form') form: ElementRef<HTMLFormElement>;


  public get firstName() : AbstractControl {
    return this.brochureForm.get('firstName');
  }
  public get lastName() : AbstractControl {
    return this.brochureForm.get('lastName');
  }
  public get email() : AbstractControl {
    return this.brochureForm.get('email');
  }
  public get company() : AbstractControl {
    return this.brochureForm.get('company');
  }
  public get country() : AbstractControl {
    return this.brochureForm.get('country');
  }


  constructor(private activatedRoute: ActivatedRoute, public formEndpointService: FormEndpointService) {
  }

  ngOnInit(): void {
    const htmlTag = document.getElementById('root-html');
    htmlTag.className = 'v2';

    this.brochureForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required
      ]),
      lastName: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      company: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),

    })
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    if (this.activatedRoute.snapshot.queryParams['errors'])
    {
      //Pardot's error messaging is nigh on useless, so we validate locally and if we get this, just display an error page
      console.log('error!');
    }
  }

  getCountry(country: string): string {
    const result = countries.getName(country, 'en', {select: "official"});
    this.country.setValue(result);
    if (result === undefined) return null;

    return result;
  }

  ngAfterViewInit(): void {
    console.log(this.formEndpointService.formEndpoint.value);
  }

  submit(): void {
    if (this.brochureForm.valid)
    {
     this.form.nativeElement.submit();
    }
    else {
      this.validateAllFormFields(this.brochureForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
