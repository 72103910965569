import { Injectable } from '@angular/core';
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { LoadingManager } from 'three';

@Injectable({
  providedIn: 'root'
})
export class ModelLoaderService {

  constructor() { }

  public async loadObjectGLTF(path: string, loadingManager: LoadingManager): Promise<GLTF> {
    const loader = new GLTFLoader(loadingManager);
    return await loader.loadAsync(path);
  }
}
