import {AnimationClip, AnimationMixer, Quaternion, Vector2} from "three";
import {Tween} from "@tweenjs/tween.js";

export class ModelAnimation {
  constructor(
    public name: string,
    public isVisible: boolean,
    public mixer: AnimationMixer,
    public clips: AnimationClip[],
    public fadeInTweens: Tween<{opacity: number}>[],
    public fadeOutTweens: Tween<{opacity: number}>[]
  ) {
  }
}
