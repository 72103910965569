import { ExperiencePage } from './experience-page';
import { ExperienceModel } from './experience-model';
import { ExperienceLight } from './experience-light';
import { ColorRepresentation, Vector3 } from 'three';
import { Type } from 'class-transformer';


export class Experience {

  constructor(
  ) {
  }

  public name: string;
  public bannerImage: string;
  public arUrl: string;
  public sceneColor: ColorRepresentation;
  public contactUsTextKey: string;
  public contactUsLinkKey: string;

  @Type(() => ExperienceLight)
  public lights: ExperienceLight[];

  @Type(() => ExperiencePage)
  public startingPage: ExperiencePage;

  @Type(() => ExperienceModel)
  public models: ExperienceModel[];
}
