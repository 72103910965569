import { AfterContentChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormEndpointService } from '../form-endpoint.service';

var countries = require("i18n-iso-countries");

@Component({
  selector: 'app-lef-form',
  templateUrl: './lef-brochure-form.component.html',
  styleUrls: ['./lef-brochure-form.component.sass']
})
export class LefBrochureFormComponent implements OnInit {

  @ViewChild('printerModel') printerModel: ElementRef<HTMLSelectElement>


  constructor(private formEndPointService: FormEndpointService) {
  }

  ngOnInit(): void {
    this.formEndPointService.formEndpoint.next('https://go.rolanddg.eu/l/181922/2021-06-03/rtmmq');
  }
}
