<h2 *ngIf="content.contentType === contentType.Header" [class]="content.className">{{content.textKey | translate}}</h2>
<p *ngIf="content.contentType === contentType.Text" [class]="content.className">{{content.textKey | translate}}</p>
<img *ngIf="content.contentType === contentType.Image" [class]="content.className" [src]="content.imagePath"
     [alt]="content.textKey | translate"/>
<ul *ngIf="content.contentType === contentType.List" [class]="content.className" class="d-flex flex-landscape-row flex-portrait-column flex-md-landscape-column flex-md-portrait-column">
  <li *ngFor="let child of content.children" [class]="child.className"
      (click)="switchPageByName(child.onClickGoToPage)">
    <div *ngIf="child.contentType === contentType.ListItem">{{child.textKey | translate}}</div>
    <a *ngIf="child.contentType === contentType.ListLinkItem" [href]="child.linkKey | translate" target="_blank">{{child.textKey | translate}}</a>
  </li>
</ul>
<div *ngIf="content.contentType === contentType.Group && content.children.length > 0" [class]="content.className">
  <app-experience-content *ngFor="let child of content.children" [content]="child"
                          [currentPage]="currentPage"></app-experience-content>
</div>
<div *ngIf="content.contentType === contentType.Accordion" class="accordion" id="content-accordion">
  <div class="accordion-item" *ngFor="let child of content.children">
    <button class="accordion-button p-0 mb-2" [ngClass]="{'collapsed': !child.isExpanded}" type="button"
            aria-expanded="false" (click)="toggleAccordion(content, child)">
      {{child.textKey | translate}}
    </button>

    <div class="accordion-collapse collapse" [ngClass]="{'show': child.isExpanded}">
      <div class="accordion-body p-0" [class]="child.className">
        <app-experience-content *ngFor="let subChild of child.children" [content]="subChild"
                                [currentPage]="currentPage"></app-experience-content>
      </div>
    </div>
  </div>
</div>
