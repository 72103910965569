import { ExperienceLightType } from './experience-light-type';
import { ColorRepresentation, Vector3 } from 'three';

export class ExperienceLight {

  constructor(
    public name: string,
    public lightType: ExperienceLightType
  ) {
  }
  public position?: Vector3;
  public lookAt?: Vector3;
  public color: ColorRepresentation;
  public skyColor: ColorRepresentation;
  public groundColor: ColorRepresentation;
  public intensity: number;
  public castsShadow: boolean = true;
}
