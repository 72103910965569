import { AfterViewInit, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { I18nCountrySelectComponent } from "ngx-i18n-country-select";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

const CUSTOM_VALUE_ACCESSOR: any = {
  provide : NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CountrySelectComponent),
  multi : true,
};
@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.sass'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class CountrySelectComponent implements ControlValueAccessor, AfterViewInit {

  @ViewChild('input') input: I18nCountrySelectComponent;

  public enCountry: string;
  public country: string;
  private disabled: boolean;
  private onChange: Function;
  private onTouched: Function;

  constructor(private translateService: TranslateService, private http: HttpClient) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;
  }

  ngAfterViewInit(): void {
    this.translateService.get('Please Select').subscribe(val => {
      this.input.defaultLabel = val;
    });
    this.input.editable = false;

    this.http.get('/api/v1/configure-form/countries')
      .subscribe((response: string[]) =>
        {
          if (!response) {
            this.input.editable = false;
            return;
          }
          this.input.editable = true;
          this.input.items = this.input.items.filter(y => response.includes(y.value));
        });
  }

  public isActive(value: string): boolean {
    return value === this.country;
  }

  public setCountry(value: string): void {
    this.country = value;
    this.onChange(this.country);
    this.onTouched();
  }

  registerOnChange(fn: any): void{
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(value: string) {
    if (value === null)
    {
      value = undefined;
      this.input.iso3166Alpha2 = undefined;
    }
    this.country = value;
    this.onChange(this.country);
    this.onTouched();
  }

  writeValue(obj: any): void {
  }
}
