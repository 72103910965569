import { EventEmitter, Injectable } from '@angular/core';
import { Experience } from './models/experience';
import { SceneService } from '../scene.service';
import { Color, LoadingManager, PerspectiveCamera, Quaternion, Vector3 } from 'three';
import { ExperienceLightType } from './models/experience-light-type';
import { BehaviorSubject, Subject } from 'rxjs';
import { ExperiencePage } from './models/experience-page';
import { environment } from '../../../../environments/environment';
import { ExperienceHotspot } from './models/experience-hotspot';
import { DimensionsService } from '../dimensions/dimensions.service';



@Injectable({
  providedIn: 'root'
})
export class ExperienceManagerService {

  constructor(private sceneService: SceneService,
              private dimensionsService: DimensionsService) {
  }

  private flattenedPages: ExperiencePage[];
  private loadingManager: LoadingManager = new LoadingManager();

  public hideLoading = new EventEmitter();
  public experienceName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public experience: Subject<Experience> = new Subject<Experience>();
  public pageSwitched: Subject<ExperiencePage> = new Subject<ExperiencePage>();
  public pageNameSwitch: Subject<string> = new Subject<string>(); //Used when the actual page can't be provided
  public isLoaded: boolean = false;

  public onLoadProgress: Subject<number> = new Subject<number>();

  public async loadExperience(experience: Experience, videoElement: HTMLVideoElement): Promise<void> {
    console.log(experience.name);

    this.loadingManager.onProgress = (url: string, itemsLoaded: number, itemsTotal: number) => this.onLoadProgressInternal(url, itemsLoaded, itemsTotal);
    this.isLoaded = false;
    this.experienceName.next(experience.name);

    this.sceneService.init(experience.startingPage.cameraPosition, experience.startingPage.cameraRotation, new Color(experience.sceneColor), videoElement, experience.startingPage.allowUserCameraRotate);
    this.sceneService.addFloorPlane();

    await this.loadLights(experience);
    await this.loadModels(experience);


    this.sceneService.start();
    this.isLoaded = true;

    this.flattenedPages = this.buildFlattenedPages(experience.startingPage);
    console.log(`loaded ${this.flattenedPages.length} pages`);
    this.experience.next(experience);
  }

  private onLoadProgressInternal(url: string, itemsLoaded: number, itemsTotal: number): void {
    this.onLoadProgress.next(itemsLoaded / itemsTotal * 100);
  }

  private buildFlattenedPages(experiencePage: ExperiencePage): ExperiencePage[] {
    let pages = [experiencePage];
    if (experiencePage.children.length > 0) {
      experiencePage.children.forEach(y => this.buildFlattenedPages(y).forEach(z => pages.push(z)));
    }
    return pages;
  }

  private async loadModels(experience: Experience): Promise<void> {
    await Promise.all(experience.models.map(async (x) => {
      const modelQuaternion = new Quaternion();

      const isHidden = experience.startingPage.hiddenModels.some(y => y == x.name);
      await this.sceneService.addModel(
        x.name,
        x.path,
        x.position,
        modelQuaternion.setFromEuler(this.sceneService.toEuler(new Vector3(x.rotation.x * (Math.PI / 180), x.rotation.y * (Math.PI / 180), x.rotation.z * (Math.PI / 180)))),
        x.scale,
        x.castShadows,
        x.receiveShadows,
        this.loadingManager,
        !isHidden);

      if (x.textureSwapData) {
        await Promise.all(x.textureSwapData.map(async (y) => {
          if (y.isVideo)
          {
            await this.sceneService.loadModelVideoTexture(y.path, y.name, y.rotation, y.flipY, y.targetMeshName, this.loadingManager);
          }
          else {
            await this.sceneService.loadModelTexture(y.path, y.name, y.rotation, y.flipY, y.targetMeshName, this.loadingManager);
          }
        }))
      }
    }));
  }

  private async loadLights(experience: Experience): Promise<void> {
    await Promise.all(experience.lights.map(async (x) => {
      switch (x.lightType) {
        case ExperienceLightType.Ambient:
          this.sceneService.addAmbientLight(x.color, x.intensity);
          break;
        case ExperienceLightType.Directional:
          if (x.lookAt === undefined || x.position === undefined) return;
          this.sceneService.addDirectionalLightLookAt(x.position, x.lookAt, x.color, x.intensity, x.castsShadow);
          break;
        case ExperienceLightType.Hemisphere:
          this.sceneService.addHemisphereLight(x.skyColor, x.groundColor, x.intensity);
          break;
      }
    }))
  }

  public switchPage(newPage: ExperiencePage): void {
    if (!newPage.allowUserCameraRotate)
    {
      this.sceneService.tweenCameraGroupToZero();
    }

    this.sceneService.tweenCameraTo(newPage.cameraPosition, newPage.cameraRotation, environment.transitionSpeed, newPage.allowUserCameraRotate);
    this.sceneService.playAnimationForPage(newPage);
    this.sceneService.fadeElementsForPage(newPage);
    this.pageSwitched.next(newPage);
  }

  public switchPageByName(name: string): void {
    const page = this.flattenedPages.find(y => y.name === name);
    if (page === undefined) {
      console.warn(`Could not find page with name ${name}`);
      return;
    }
    this.switchPage(page);
  }

  updateHotspots(currentPage: ExperiencePage, transitioningPage: ExperiencePage): void {
    transitioningPage?.hotspots.forEach((hotspot) => {
      this.updateHotSpot(hotspot);
    });
    currentPage?.hotspots.forEach((hotspot) => {
      this.updateHotSpot(hotspot);
    });
  }

  updateHotSpot(hotspot: ExperienceHotspot): void {
    const tempPos = new Vector3();
    tempPos.copy(hotspot.position);
    tempPos.project(this.sceneService.camera);

    let canvas = this.sceneService.getCanvas();
    //const dimensions = this.dimensionsService.getWindowDimensions();
    const x = (tempPos.x * 0.5 + 0.5) * canvas.clientWidth;
    const y = (tempPos.y * -0.5 + 0.5) * canvas.clientHeight;

    hotspot.position2D.set(x, y);
  }

  public onWindowResize(currentPage: ExperiencePage, transitioningPage: ExperiencePage): void {
    this.sceneService.onWindowResize();
    this.updateHotspots(currentPage, transitioningPage);
  }

  public onDestroy(): void {
    this.sceneService.onDestroy();
  }

  rotateCamera(mouseX: number) {
    this.sceneService.rotateCamera(mouseX);
  }
}
