<div id="unity-container" class="unity-desktop">
  <canvas id="unity-canvas" width="1800" height="1472" style="cursor: default;"></canvas>
</div>
<div id="loading-cover" #loadingCover>
  <div id="unity-loading-bar">
    <div id="unity-logo"><img src="assets/images/v2/RolandLogo.svg" alt="Roland"></div>
    <div id="loading-spinner">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 200">
        <defs>
          <clipPath id="e5dfb549-42dd-427c-a69d-7921e21e5f4e">
            <path class="ed98fa8d-a3c8-4f1d-bb65-d0ca2bc79f51" d="M102.09,63.77a3.08,3.08,0,0,0-4.14,0l0,0c-1.07,1.1-26.29,27-26.29,46.8C71.62,128.14,81.17,137,100,137s28.38-8.9,28.38-26.47C128.38,90.76,103.16,64.87,102.09,63.77Z"></path>
          </clipPath>
        </defs>
        <g id="e3f69cf4-13de-4046-b884-1852f9089464" data-name="Layer 2">
          <g id="e1e5b44a-4321-46aa-b731-8b199b7ee11a" data-name="Layer 1">
            <g>
              <g id="abf77380-7dc1-4cf6-b1fe-e7fac1b76fd8" data-name="Fill Bar">
                <g class="a69586ec-69a2-4b17-a5dc-75fe1a6235f6">
                  <rect id="f2344392-03e0-4fa5-b7f2-ab6f6bbc1179" data-name="Fill" class="be26692e-a43c-4caa-aa9c-b6d97cb96b99 fill" x="67.86" y="58" width="62.72" height="78"></rect>
                </g>
              </g>
              <path id="b9db3d72-5e08-4d24-9c45-286c7ca8d04b" data-name="Fill Edge" class="aa11ef2d-97ba-433c-9630-bdc70d90656e" d="M102.09,63.77a3.08,3.08,0,0,0-4.14,0l0,0c-1.07,1.1-26.29,27-26.29,46.8C71.62,128.14,81.17,137,100,137s28.38-8.9,28.38-26.47C128.38,90.76,103.16,64.87,102.09,63.77Z"></path>
              <path id="eb84bd25-b38b-46e0-bd9a-0e1d691387ef" data-name="Rotate Edges" class="be26692e-a43c-4caa-aa9c-b6d97cb96b99" d="M16.12,108.5a83.88,83.88,0,0,0,4.49,32.68,86.44,86.44,0,0,0,17.21,29.11,88.86,88.86,0,0,0,27.75,20.79,90.5,90.5,0,0,0,16.68,6.06A93.41,93.41,0,0,0,100,199.86a93.91,93.91,0,0,1-67.87-23.92A96.39,96.39,0,0,1,2.91,127.68,98.86,98.86,0,0,1,0,108.5v-.07a8.07,8.07,0,0,1,16.11-.83Q16.13,108.05,16.12,108.5ZM183.88,92.4A8.07,8.07,0,0,0,200,91.57V91.5a98.86,98.86,0,0,0-2.9-19.18A95.91,95.91,0,0,0,136,5.22,93.34,93.34,0,0,0,100,.14a93.41,93.41,0,0,1,17.75,2.72,90.5,90.5,0,0,1,16.68,6.06,88.86,88.86,0,0,1,27.75,20.79,86.44,86.44,0,0,1,17.21,29.11,83.88,83.88,0,0,1,4.49,32.68Q183.87,91.95,183.88,92.4Z">
                <animateTransform attributeName="transform" type="rotate" from="0 100 100" to="360 100 100" begin="0s" dur="2s" repeatCount="indefinite"></animateTransform>
              </path>
            </g>
          </g>
        </g>
      </svg>
    </div>
    <div id="loading-text">
      <div id="fact-title">
        <p class="testimonial-title">{{'StickerShop' | translate}}</p>
        <p class="testimonial-title">{{'Peak UK' | translate}}</p>
        <p class="testimonial-title">{{'Taymory' | translate}}</p>
        <p class="testimonial-title">{{'The Binary Box' | translate}}</p>
      </div>
      <div id="fact-body">
        <p class="testimonial-text">"{{'Because we deal with such high volumes, the reliability and build quality of the Roland printers is just what we need' | translate}}"</p>
        <p class="testimonial-text">"{{'the best thing about the Roland DG machine is that it offers complete flexibility' | translate}}"</p>
        <p class="testimonial-text">"{{'Working with Roland gives us great flexibility' | translate}}"</p>
        <p class="testimonial-text">"{{'It offers vibrancy and quality of print, great reliability and sleek design and build quality Its a workhorse that always delivers' | translate}}"</p>
      </div>
    </div>
  </div>
</div>
<div id="unity-fullscreen-button" style="display: none;"></div>
<a #iosARLink [href]="getIoSModelUrl()" rel="ar" class="d-none">
  <img/>
</a>
<a #androidARLink [href]="getAndroidModelIntentUrl()"></a>

