import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AugmentedRealityService } from './augmented-reality.service';

@Component({
  selector: 'app-experience-augmented-reality-button',
  templateUrl: './experience-augmented-reality-button.component.html',
  styleUrls: ['./experience-augmented-reality-button.component.sass']
})
export class ExperienceAugmentedRealityButtonComponent implements OnInit {

  @Input() arUrl: string = '';
  @Input() arTextKey: string = '';

  constructor(private arService: AugmentedRealityService) {
  }

  public isShown: boolean = false;
  public fullARUrl: string = '';

  ngOnInit(): void {
    const url = (new URL(document.URL));
    this.fullARUrl = url.origin + this.arUrl;
  }

  async showAr(): Promise<void> {
    let result = await this.arService.showAr(this.arUrl);
    if (!result)
    {
      if (this.arUrl !== undefined && this.arUrl !== null && this.arUrl !== '') {
        this.isShown = !this.isShown;
      } else {
        this.isShown = false;
      }
    }
  }


  closeAr() {
    this.isShown = false;
  }
}
