export enum ExperienceContentType {
  Header = "Header",
  Image = "Image",
  Text = "Text",
  List = "List",
  ListItem = "ListItem",
  CollapsableList = "CollapsableList",
  Group = "Group",
  Accordion = "Accordion",
  AccordionItem = "AccordionItem",
  ListLinkItem = "ListLinkItem",
}
