<form #form [action]="formEndpointService.formEndpoint | async" method="post" [formGroup]="consultationForm">
  <ng-content></ng-content>
  <div class="form-row">
    <div class="form-group col-sm-6 col-12">
      <label>{{'First Name' | translate}}</label>
      <input type="text" name="First Name" class="form-control" required="required" formControlName="firstName"/>
      <p class="text-danger" *ngIf="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors.required">{{'First Name is required' | translate}}</p>
    </div>
    <div class="form-group col-sm-6 col-12">
      <label>{{'Last Name' | translate}}</label>
      <input type="text" name="Last Name" class="form-control" required="required" formControlName="lastName"/>
      <p class="text-danger" *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors.required">{{'Last Name is required' | translate}}</p>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6 col-12">
      <label>{{'Email Address' | translate}}</label>
      <input type="email" name="Email Address" class="form-control" required="required" formControlName="email"/>
      <p class="text-danger" *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.required">{{'Email is required' | translate}}</p>
      <p class="text-danger" *ngIf="email.invalid && (email.dirty || email.touched) && email.errors.email">{{'Email must be a valid email address' | translate}}</p>
    </div>
    <div class="form-group col-sm-6 col-12">
      <label>{{'Phone Number' | translate}}</label>
      <input type="email" name="Phone Number" class="form-control" required="required" formControlName="phoneNumber" />
      <p class="text-danger" *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched) && phoneNumber.errors.required">{{'Phone Number is required' | translate}}</p>
      <p class="text-danger" *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched) && phoneNumber.errors.min">{{'Phone Number must be a valid phone number' | translate}}</p>
      <p class="text-danger" *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched) && phoneNumber.errors.pattern">{{'Phone Number must be a valid phone number' | translate}}</p>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6 col-12">
      <label>{{'Company' | translate}}</label>
      <input type="text" name="Company Name" class="form-control" required="required" formControlName="company"/>
      <p class="text-danger" *ngIf="company.invalid && (company.dirty || company.touched) && company.errors.required">{{'Company is required' | translate}}</p>
    </div>
    <div class="form-group col-sm-6 col-12">
      <label>{{'Country' | translate}}</label>
      <app-country-select #countrySelect [ngClass]="{'p-0 col-6': true }"></app-country-select>
      <input type="hidden" name="Country" required="required" [value]="getCountry(countrySelect.country)" formControlName="country"/>
      <p class="text-danger" *ngIf="country.invalid && (country.dirty || country.touched) && country.errors.required">{{'Country is required' | translate}}</p>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-sm-6 col-12">
      <label>{{'Address' | translate}}</label>
      <input type="text" multiple="multiple" name="Address" class="form-control" required="required" formControlName="address"/>
      <p class="text-danger" *ngIf="address.invalid && (address.dirty || address.touched) && address.errors.required">{{'Address is required' | translate}}</p>
    </div>
    <div class="form-group col-sm-6 col-12">
      <label>{{'Postcode' | translate}}</label>
      <input type="text" name="Postcode" class="form-control" required="required" formControlName="postcode"/>
      <p class="text-danger" *ngIf="postcode.invalid && (postcode.dirty || postcode.touched) && postcode.errors.required">{{'Postcode is required' | translate}}</p>
    </div>
  </div>
  <div class="form-group">
    <label>{{'Job Position' | translate}}</label>
    <select name="Job Position" required="required" class="form-control" formControlName="jobPosition">
      <option value="">{{'Please select a job position' | translate}}</option>
      <option value="President/Owner">{{'President/Owner' | translate}}</option>
      <option value="C-Level (CEO, CIO, COO, CFO)">{{'C-Level (CEO, CIO, COO, CFO)' | translate}}</option>
      <option value="Director">{{'Director' | translate}}</option>
      <option value="Manager">{{'Manager' | translate}}</option>
      <option value="Supervisor/Foreman">{{'Supervisor/Foreman' | translate}}</option>
      <option value="Engineer/Technician">{{'Engineer/Technician' | translate}}</option>
      <option value="Officer/Specialist/Staff">{{'Officer/Specialist/Staff' | translate}}</option>
      <option value="Consultant">{{'Consultant' | translate}}</option>
      <option value="Teacher/Lecturer">{{'Teacher/Lecturer' | translate}}</option>
      <option value="Student/Intern">{{'Student/Intern' | translate}}</option>
      <option value="Other">{{'Other' | translate}}</option>
    </select>
    <p class="text-danger" *ngIf="jobPosition.invalid && (jobPosition.dirty || jobPosition.touched) && jobPosition.errors.required">{{'Job Position is required' | translate}}</p>
  </div>
  <div class="form-group">
    <label>{{'Which industry are you interested in?' | translate}}</label>
    <select name="Industry" required="required" class="form-control" formControlName="industry" (change)="onIndustryChange()">
      <option value="">{{'Please select an industry' | translate}}</option>
      <option value="Medical, Dental">{{'Medical, Dental' | translate}}</option>
      <option value="Not for Profit">{{'Not for Profit' | translate}}</option>
      <option value="Retail, B2C">{{'Retail, B2C' | translate}}</option>
      <option value="Signs, Graphics">{{'Signs, Graphics' | translate}}</option>
      <option value="Print Service Provider, Packaging">{{'Print Service Provider, Packaging' | translate}}</option>
      <option value="Textiles, Apparel">{{'Textiles, Apparel' | translate}}</option>
      <option value="Manufacturing, Industrial">{{'Manufacturing, Industrial' | translate}}</option>
      <option value="Wholesale Suppliers, Distributors">{{'Wholesale Suppliers, Distributors' | translate}}</option>
      <option value="Design Services">{{'Design Services' | translate}}</option>
    </select>
    <p class="text-danger" *ngIf="industry.invalid && (industry.dirty || industry.touched) && industry.errors.required">{{'Industry is required' | translate}}</p>
  </div>
  <input type="hidden" name="source" value="Virtual Product" />
  <div class="form-group d-flex justify-content-center align-items-center w-100">
    <input type="button" id="btnSubmit" name="btnSubmit" class="btn btn-primary mt-2" (click)="submit()"
           value="{{'Send Request' | translate}}"/>
  </div>
</form>
