import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruevisVg2Component } from './truevis-vg2/truevis-vg2.component';
import { v2TruevisVg2RoutingModule } from './v2-routing.module';
import { UnityLoaderComponent } from './unity-loader/unity-loader.component';
import { LefBrochureFormComponent } from './forms/lef-brochure-form/lef-brochure-form.component';

import { SharedModule } from '../shared/shared.module';
import { SuccessComponent } from './forms/success/success.component';
import { LefConsultantFormComponent } from './forms/lef-consultant-form/lef-consultant-form.component';
import { Lec2SSeriesComponent } from './lec2-s-series/lec2-s-series.component';
import { Sg2Component } from './sg2/sg2.component';
import { LefComponent } from './lef2-300/lef2300.component';
import { Vf2640Component } from './vf2-640/vf2640.component';
import { Iu1000fComponent } from './iu-1000f/iu1000f.component';
import { Lec2640Component } from './lec2-640/lec2640.component';
import { Xt640sComponent } from './xt-640s/xt640s.component';
import { DefaultBrochureFormComponent } from './forms/default-brochure-form/default-brochure-form.component';
import { DefaultConsultationFormComponent } from './forms/default-consultation-form/default-consultation-form.component';
import { Vg2BrochureFormComponent } from './forms/vg2-brochure-form/vg2-brochure-form.component';
import { Vg2ConsultantFormComponent } from './forms/vg2-consultant-form/vg2-consultant-form.component';
import { XtBrochureFormComponent } from './forms/xt-brochure-form/xt-brochure-form.component';
import { XtConsultantFormComponent } from './forms/xt-consultant-form/xt-consultant-form.component';
import { Lec2BrochureFormComponent } from './forms/lec2-brochure-form/lec2-brochure-form.component';
import { Lec2ConsultantFormComponent } from './forms/lec2-consultant-form/lec2-consultant-form.component';
import { Vf2BrochureFormComponent } from './forms/vf2-brochure-form/vf2-brochure-form.component';
import { Vf2ConsultantFormComponent } from './forms/vf2-consultant-form/vf2-consultant-form.component';
import { IuBrochureFormComponent } from './forms/iu-brochure-form/iu-brochure-form.component';
import { IuConsultantFormComponent } from './forms/iu-consultant-form/iu-consultant-form.component';
import { Sg2BrochureFormComponent } from './forms/sg2-brochure-form/sg2-brochure-form.component';
import { Sg2ConsultantFormComponent } from './forms/sg2-consultant-form/sg2-consultant-form.component';
import { Lec2SSeriesBrochureFormComponent } from './forms/lec2-s-series-brochure-form/lec2-s-series-brochure-form.component';
import { Lec2SSeriesConsultantFormComponent } from './forms/lec2-s-series-consultant-form/lec2-s-series-consultant-form.component';



@NgModule({
  declarations: [
    TruevisVg2Component,
    UnityLoaderComponent,
    LefComponent,
    LefBrochureFormComponent,
    SuccessComponent,
    LefConsultantFormComponent,
    Iu1000fComponent,
    Lec2640Component,
    Lec2SSeriesComponent,
    Sg2Component,
    Vf2640Component,
    Xt640sComponent,
    DefaultBrochureFormComponent,
    DefaultConsultationFormComponent,
    Vg2BrochureFormComponent,
    Vg2ConsultantFormComponent,
    XtBrochureFormComponent,
    XtConsultantFormComponent,
    Lec2BrochureFormComponent,
    Lec2ConsultantFormComponent,
    Vf2BrochureFormComponent,
    Vf2ConsultantFormComponent,
    IuBrochureFormComponent,
    IuConsultantFormComponent,
    Sg2BrochureFormComponent,
    Sg2ConsultantFormComponent,
    Lec2SSeriesBrochureFormComponent,
    Lec2SSeriesConsultantFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    v2TruevisVg2RoutingModule
  ]
})
export class V2Module {
}
