import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormEndpointService } from '../form-endpoint.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

const countries = require("i18n-iso-countries");

@Component({
  selector: 'app-default-consultation-form',
  templateUrl: './default-consultation-form.component.html',
  styleUrls: ['./default-consultation-form.component.sass']
})
export class DefaultConsultationFormComponent implements OnInit {
  public consultationForm: FormGroup;
  @ViewChild('form') form: ElementRef<HTMLFormElement>;

  constructor(private activatedRoute: ActivatedRoute, public formEndpointService: FormEndpointService) {
  }

  public get firstName() : AbstractControl {
    return this.consultationForm.get('firstName');
  }
  public get lastName() : AbstractControl {
    return this.consultationForm.get('lastName');
  }
  public get email() : AbstractControl {
    return this.consultationForm.get('email');
  }
  public get phoneNumber() : AbstractControl {
    return this.consultationForm.get('phoneNumber');
  }
  public get company() : AbstractControl {
    return this.consultationForm.get('company');
  }
  public get country() : AbstractControl {
    return this.consultationForm.get('country');
  }
  public get address() : AbstractControl {
    return this.consultationForm.get('address');
  }
  public get postcode() : AbstractControl {
    return this.consultationForm.get('postcode');
  }
  public get jobPosition() : AbstractControl {
    return this.consultationForm.get('jobPosition');
  }
  public get industry() : AbstractControl {
    return this.consultationForm.get('industry');
  }
  ngOnInit(): void {
    const htmlTag = document.getElementById('root-html');
    htmlTag.className = 'v2';

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    if (this.activatedRoute.snapshot.queryParams['errors']) {
      //Pardot's error messaging is nigh on useless, so we validate locally and if we get this, just display an error page
      console.log('error!');
    }

    this.consultationForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required
      ]),
      lastName: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.min(6),
        Validators.pattern('[- +()0-9]+')
      ]),
      company: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ]),
      address: new FormControl('', [
        Validators.required
      ]),
      postcode: new FormControl('', [
        Validators.required
      ]),
      jobPosition: new FormControl('', [
        Validators.required
      ]),
      industry: new FormControl('', [
        Validators.required
      ]),
    })
  }

  getCountry(country: string): string {
    const result = countries.getName(country, 'en', {select: "official"});
    this.country.setValue(result);
    if (result === undefined) return null;

    return result;
  }

  submit(): void {
    if (this.consultationForm.valid)
    {
      this.form.nativeElement.submit();
    }
    else {
      this.validateAllFormFields(this.consultationForm);
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onIndustryChange(): void {

  }

}
