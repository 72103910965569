import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormEndpointService } from '../form-endpoint.service';

const countries = require("i18n-iso-countries");

@Component({
  selector: 'app-lef-consultant-form',
  templateUrl: './lef-consultant-form.component.html',
  styleUrls: ['./lef-consultant-form.component.sass']
})
export class LefConsultantFormComponent implements OnInit {

  @ViewChild('printerModel') printerModel: ElementRef<HTMLSelectElement>

  constructor(private formEndPointService: FormEndpointService) {
  }

  ngOnInit(): void {
    this.formEndPointService.formEndpoint.next('https://go.rolanddg.eu/l/181922/2021-03-23/qgjk2');
  }
}
