import { EventEmitter, Injectable } from '@angular/core';
import {
  AmbientLight,
  AnimationMixer,
  Clock,
  Color,
  ColorRepresentation,
  CubeUVReflectionMapping,
  DataTexture,
  DirectionalLight,
  EquirectangularReflectionMapping,
  Euler,
  Group,
  HemisphereLight,
  LoadingManager,
  LoopOnce,
  MathUtils,
  Mesh,
  MeshLambertMaterial,
  MeshStandardMaterial,
  Object3D,
  PCFSoftShadowMap,
  PerspectiveCamera,
  PlaneBufferGeometry,
  PMREMGenerator,
  Quaternion,
  Raycaster,
  ReinhardToneMapping,
  RepeatWrapping,
  RGBADepthPacking,
  RGBAFormat,
  Scene,
  ShaderLib,
  ShaderMaterial,
  ShadowMaterial,
  sRGBEncoding,
  Texture,
  TextureLoader,
  Vector2,
  Vector3,
  VideoTexture,
  WebGLRenderer,
  WebGLRenderTarget
} from 'three';
import { ModelLoaderService } from "./model-loader.service";
import { Easing, Tween, update } from '@tweenjs/tween.js';
import { ExperiencePage } from "./experience/models/experience-page";
import { ModelAnimation } from "./model-animation";
import { Reflector } from "three/examples/jsm/objects/Reflector";
import { ScreenDoorShaderService } from "./effects/screen-door-shader.service";
import { FlyControls } from "three/examples/jsm/controls/FlyControls";
import { environment } from "../../../environments/environment";
import { TextureData } from "./texture-data";
import { TextureLoaderService } from "./texture-loader.service";
import { FileLoaderService } from './file-loader.service';


@Injectable({
  providedIn: 'root'
})
export class SceneService {
  private scene: Scene;
  private cameraGroup: Group = new Group();
  public camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private ditherTexture: DataTexture;
  private shadowTarget: WebGLRenderTarget;
  private floorGroup: Group = new Group();
  private envMap: Texture;
  private pmremGenerator: PMREMGenerator;
  private clock: Clock;
  private animations: ModelAnimation[] = [];
  private textureData: TextureData[] = [];
  private video: HTMLVideoElement;

  private controls: FlyControls;
  public updateEvent: EventEmitter<void> = new EventEmitter<void>();
  public inSceneViewMode: boolean = false;

  private rotateRangeDeg = 5;
  private portraitFov = 80;
  private landscapeFov = 50;
  private allowUserCameraPan: boolean;
  private startCameraGroupRotation: Quaternion = new Quaternion();
  private targetCameraGroupRotation: Quaternion = new Quaternion();
  private targetCameraGroupRotationTime: number = 0;
  private maxCameraGroupRotationTime: number = 0.35;
  private cameraGroupRotationSpeed: number = 2;

  constructor(private modelLoaderService: ModelLoaderService,
              private screenDoorShaderService: ScreenDoorShaderService,
              private textureLoaderService: TextureLoaderService,
              private fileLoaderService: FileLoaderService) {
  }

  init(initialCameraPosition: Vector3, cameraRotation: Vector3, background: Color, videoElement: HTMLVideoElement, allowUserCameraRotate: boolean = false): void {

    this.animations = [];
    this.video = videoElement;
    this.clock = new Clock();
    this.scene = new Scene();
    this.scene.background = background;

    this.ditherTexture = this.screenDoorShaderService.createDitherTexture();

    this.renderer = new WebGLRenderer({ antialias: true });
    this.renderer.domElement.className = "no-mobile-landscape";
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.renderer.toneMapping = ReinhardToneMapping;
    this.renderer.toneMappingExposure = 3;
    this.renderer.domElement.className = "full-screen";

    this.cameraGroup = new Group();
    this.camera = new PerspectiveCamera(50, 1, 0.01, 1000);
    this.camera.layers.enableAll();
    this.cameraGroup.add(this.camera);

    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    const textureLoader = new TextureLoader();
    textureLoader.load(
      '/assets/v3/textures/env-map.jpg',
      (texture) => {
        texture.mapping = EquirectangularReflectionMapping;
        const processedTexture: Texture = this.pmremGenerator.fromEquirectangular(texture).texture;
        processedTexture.mapping = CubeUVReflectionMapping;
        this.scene.environment = processedTexture;
      }
    );

    this.shadowTarget = new WebGLRenderTarget(1024, 1024, { format: RGBAFormat })

    this.scene.add(this.cameraGroup);
    this.jumpCameraTo(initialCameraPosition, cameraRotation, allowUserCameraRotate);
  }

  addFloorPlane(size: number = 500): void {
    const dimensions = this.renderer.domElement;
    // generate floor plane mesh
    const geometry = new PlaneBufferGeometry(size, size);
    geometry.rotateX(-Math.PI / 2);

    const material = new MeshLambertMaterial({
      transparent: true,
      opacity: 0.95,
      emissive: new Color(1, 1, 1),
      emissiveIntensity: 50
    });
    const floorPlane = new Mesh(geometry, material);
    floorPlane.layers.enableAll();
    floorPlane.frustumCulled = false;
    floorPlane.renderOrder = 1;
    this.floorGroup.add(floorPlane);

    //generate under-floor reflector
    const reflectionPlane = new Reflector(new PlaneBufferGeometry(size, size), {
      color: '#d4d4d4',
      textureWidth: dimensions.width * window.devicePixelRatio,
      textureHeight: dimensions.height * window.devicePixelRatio
    });

    reflectionPlane.position.y = -0.02;
    reflectionPlane.rotation.x = -Math.PI / 2;
    reflectionPlane.frustumCulled = false;

    this.floorGroup.add(reflectionPlane);

    // generate shadow layer
    const shadowGeometry = new PlaneBufferGeometry(size, size);
    shadowGeometry.rotateX(-Math.PI / 2);

    const shadowMaterial = new ShadowMaterial();
    shadowMaterial.opacity = 0.2;

    const shadowPlane = new Mesh(shadowGeometry, shadowMaterial);
    shadowPlane.position.y = 0.02;
    shadowPlane.receiveShadow = true;
    shadowPlane.frustumCulled = false;
    shadowPlane.renderOrder = 2;
    this.floorGroup.add(shadowPlane);

    this.scene.add(this.floorGroup);
  }

  addAmbientLight(color: ColorRepresentation = 0x222222, intensity: number = 1): void {
    const light = new AmbientLight(color, intensity);
    this.scene.add(light);
  }

  addHemisphereLight(skyColor: ColorRepresentation, groundColor: ColorRepresentation, intensity: number = 1): void {
    const light = new HemisphereLight(skyColor, groundColor, intensity);
    this.scene.add(light);
  }

  addDirectionalLightLookAt(position: Vector3, lookAt: Vector3, color: ColorRepresentation, intensity: number = 1, castsShadow: boolean = true): void {
    const light = new DirectionalLight(color, intensity);
    light.position.copy(position);
    light.castShadow = castsShadow;

    if (castsShadow) {
      light.shadow.bias = -0.0005;

      const size = 28;
      light.shadow.camera.near = 0.01;
      light.shadow.camera.far = 1000;
      light.shadow.camera.top = size;
      light.shadow.camera.bottom = -size;
      light.shadow.camera.left = size;
      light.shadow.camera.right = -size;

    }

    const target = new Object3D();
    target.position.copy(lookAt);
    this.scene.add(target);
    light.target = target;


    this.scene.add(light);
  }

  async addModel(name: string, path: string, position: Vector3, quaternion: Quaternion, scale: Vector3, castShadows: boolean, receiveShadows: boolean, loadingManager: LoadingManager, startsVisible: boolean = true): Promise<void> {
    return await this.modelLoaderService.loadObjectGLTF(path, loadingManager)
      .then((model) => {
        model.scene.position.copy(position);
        model.scene.quaternion.copy(quaternion);
        model.scene.scale.copy(scale);

        let fadeInTweens: Tween<{ opacity: number }>[] = [];
        let fadeOutTweens: Tween<{ opacity: number }>[] = [];

        model.scene.traverse((node) => {

          if (node.type === "Mesh") {

            node.castShadow = castShadows;
            node.receiveShadow = receiveShadows;
            node.renderOrder = 3;

            let depthShader = this.screenDoorShaderService.updateShader(ShaderLib.depth); //TODO - Probably highly inefficient, but required to stop one tween making all shadows vanish
            depthShader.fragmentShader = `uniform float opacity;\n${depthShader.fragmentShader}`;

            const startingOpacity = startsVisible ? 1 : 0;
            let depthMat = new ShaderMaterial(depthShader);
            depthMat.defines.DEPTH_PACKING = RGBADepthPacking;
            depthMat.uniforms.ditherTex.value = this.ditherTexture;
            depthMat.uniforms.opacity.value = startingOpacity;

            ((node as Mesh).material as MeshStandardMaterial).transparent = true;
            ((node as Mesh).material as MeshStandardMaterial).opacity = startingOpacity;
            ((node as Mesh).material as MeshStandardMaterial).envMapIntensity = 2;

            if (node.name === "phone" || node.name === "Screen" || node.name === "teeshirt") {
              ((node as Mesh).material as MeshStandardMaterial).metalness = 0;
            }

            (node as Mesh).customDepthMaterial = depthMat;

            console.log(`adding mesh for ${name} - ${node.name}`);
            let opacity = { opacity: startingOpacity };
            let fadeOutTween = new Tween(opacity).to({ opacity: 0 }, 2000)
              .onUpdate(() => {
                ((node as Mesh).material as MeshStandardMaterial).opacity = opacity.opacity;
                (node.customDepthMaterial as ShaderMaterial).uniforms.opacity.value = opacity.opacity;
              });

            fadeOutTweens.push(fadeOutTween);

            let fadeInTween = new Tween(opacity).to({ opacity: 1 }, 2000)
              .onUpdate(() => {
                ((node as Mesh).material as MeshStandardMaterial).opacity = opacity.opacity;
                (node.customDepthMaterial as ShaderMaterial).uniforms.opacity.value = opacity.opacity;
              });
            fadeInTweens.push(fadeInTween);
          }
        });
        const animation = new ModelAnimation(name, startsVisible, new AnimationMixer(model.scene), model.animations, fadeInTweens, fadeOutTweens);
        this.animations.push(animation);
        this.scene.add(model.scene);
      });
  }

  async loadModelVideoTexture(path: string, name: string, rotation: number, flipY: boolean, targetMeshName: string, loadManager: LoadingManager): Promise<void> {
    return await this.fileLoaderService.loadFile(path, loadManager)
      .then((fileBuffer: string | ArrayBuffer) => {
        const blob = new Blob([fileBuffer as ArrayBuffer], { type: 'video/mp4' });

        const videoTexture = new VideoTexture(this.video);
        videoTexture.wrapS = RepeatWrapping;
        videoTexture.wrapT = RepeatWrapping;
        videoTexture.encoding = sRGBEncoding;
        videoTexture.rotation = rotation * (Math.PI / 180);
        videoTexture.flipY = flipY;
        videoTexture.name = name;

        let mesh: Mesh;
        this.scene.traverse((node) => {
          if (node.name === targetMeshName) {
            mesh = (node as Mesh);
          }
        })

        const data = new TextureData(name, videoTexture, mesh, true);
        data.videoUrl = URL.createObjectURL(blob);
        console.log(data.videoUrl);
        this.textureData.push(data);
      });
  }

  async loadModelTexture(path: string, name: string, rotation: number, flipY: boolean, targetMeshName: string, loadManager: LoadingManager): Promise<void> {
    return await this.textureLoaderService.loadTexture(path, loadManager)
      .then((texture) => {
        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;
        texture.encoding = sRGBEncoding;

        texture.rotation = rotation * (Math.PI / 180);
        texture.flipY = flipY;
        texture.name = name;

        let mesh: Mesh;
        this.scene.traverse((node) => {
          if (node.name === targetMeshName) {
            mesh = (node as Mesh);
          }
        })

        // generate default texture data for mesh, if not saved already.
        const defaultName = name.endsWith('_EM') ? `${mesh.name}_default_EM` : `${mesh.name}_default`;
        const existing = this.textureData.find((x) => x.name === defaultName);

        if (!existing) {
          const defaultData = new TextureData(defaultName, (mesh.material as MeshStandardMaterial).map, mesh);
          this.textureData.push(defaultData);
        }

        const data = new TextureData(name, texture, mesh);
        this.textureData.push(data);
      });
  }

  start(): void {

    document.body.appendChild(this.renderer.domElement);
    this.manageDisplaySize();
    if (!environment.production) {
      this.controls = new FlyControls(this.camera, this.renderer.domElement);
      this.controls.domElement = this.renderer.domElement;
      this.controls.autoForward = false;
      this.controls.dragToLook = true;
      this.controls.rollSpeed = Math.PI / 24;
    }

    const me = this;

    (function render() {
      let camWorldQuaternion: Quaternion = new Quaternion();
      me.camera.getWorldQuaternion(camWorldQuaternion);
      let camWorldEuler: Euler = new Euler();
      camWorldEuler.setFromQuaternion(camWorldQuaternion);
      me.floorGroup.rotation.y = camWorldEuler.y;

      me.updateEvent.emit();
      update();
      const delta = me.clock.getDelta();
      me.animations.forEach((x) => {
        x.mixer.update(delta);
      });

      if (me.controls && !environment.production && me.inSceneViewMode) {
        me.controls.update(delta);
      }

      if (me.targetCameraGroupRotationTime < me.maxCameraGroupRotationTime && me.allowUserCameraPan) {
        me.targetCameraGroupRotationTime += delta;
        let current = new Quaternion().slerpQuaternions(me.startCameraGroupRotation, me.targetCameraGroupRotation,me.cameraGroupRotationSpeed * me.targetCameraGroupRotationTime);
        me.cameraGroup.quaternion.copy(current);
      }


      let tempClearColor: Color = new Color();
      me.renderer.getClearColor(tempClearColor);
      me.renderer.setClearColor(0);
      me.renderer.setRenderTarget(me.shadowTarget);
      me.renderer.clearColor();
      me.renderer.setRenderTarget(null);
      me.renderer.setClearColor(tempClearColor);

      me.renderer.render(me.scene, me.camera);
      requestAnimationFrame(render);
    }());
  }

  jumpCameraTo(cameraPosition: Vector3, cameraRotation: Vector3, allowUserCameraPan: boolean = false): void {
    this.camera.position.copy(cameraPosition);
    this.camera.quaternion.copy(new Quaternion().setFromEuler(new Euler(
      cameraRotation.x,
      cameraRotation.y,
      cameraRotation.z, 'XYZ')));
    this.allowUserCameraPan = allowUserCameraPan;
  }

  tweenCameraTo(cameraPosition: Vector3, cameraRotation: Vector3, durationMs: number = 2000, allowUserCameraPanOnComplete: boolean = false): void {

    if (!allowUserCameraPanOnComplete)
    {
      this.targetCameraGroupRotation = new Quaternion().setFromEuler(new Euler(0, 0, 0, 'XYZ'));
      this.startCameraGroupRotation = new Quaternion().copy(this.cameraGroup.quaternion);
      this.targetCameraGroupRotationTime = this.maxCameraGroupRotationTime;
      this.allowUserCameraPan = allowUserCameraPanOnComplete; //Update immediately
    }

    let positionTween = new Tween(this.camera.position)
      .to(cameraPosition, durationMs)
      .easing(Easing.Quadratic.InOut);

    let rot = new Quaternion().copy(this.camera.quaternion);
    let targetRot = new Quaternion()
      .setFromEuler(new Euler(
        cameraRotation.x,
        cameraRotation.y,
        cameraRotation.z));

    let currentTime = new Vector2(0, 0);
    let maxTime = new Vector2(1, 0);
    let currentQuat = new Quaternion();
    let rotationTween = new Tween(currentTime)
      .to(maxTime, durationMs)
      .easing(Easing.Quadratic.InOut)
      .onUpdate(() => {
        currentQuat.slerpQuaternions(rot, targetRot, currentTime.x);
        this.camera.quaternion.copy(currentQuat);
      })
      .onComplete(() => {
        this.allowUserCameraPan = allowUserCameraPanOnComplete;
      })

    positionTween.start();
    rotationTween.start();
  }

  playAnimationForPage(currentPage: ExperiencePage): void {
    this.animations.forEach((x) => {
      x.mixer.stopAllAction();
    })
    currentPage.animations.forEach((x) => {
      const modelAnimation = this.animations.find((y) => y.name === x.modelName);
      const modelClip = modelAnimation?.clips.find((z) => z.name === x.animationName);

      const action = modelAnimation.mixer.clipAction(modelClip);

      if (!x.loop) {
        action.loop = LoopOnce;
      }

      action.play();
    })
  }

  fadeElementsForPage(currentPage: ExperiencePage): void {
    this.animations.forEach((x) => {
      const isHidingModel = currentPage.hiddenModels.some((y) => x.name === y);
      if (!x.isVisible && !isHidingModel) {
        x.fadeInTweens.forEach((y) => {
          // play fade in animation
          y.start();
        });
        x.isVisible = true;
      } else if (x.isVisible && isHidingModel) {
        x.fadeOutTweens.forEach((y) => {
          // play fade out animation
          y.start();
        });
        x.isVisible = false;
      }
    })
  }

  swapTextureOnMesh(textureName: string): void {
    const data = this.textureData.find((x) => x.name === textureName);

    if (!data) return;
    if (data.isVideo) {
      this.video.src = data.videoUrl;
      this.video.play()
        .then(() => {
          if (data.name.endsWith("_EM")) {
            (data.targetMesh.material as MeshStandardMaterial).emissiveMap = data.texture;
          } else {
            (data.targetMesh.material as MeshStandardMaterial).map = data.texture;
          }
          (data.targetMesh.material as MeshStandardMaterial).needsUpdate = true;
        });
      return;
    }

    if (data.name.endsWith("_EM")) {
      (data.targetMesh.material as MeshStandardMaterial).emissiveMap = data.texture;
    } else {
      (data.targetMesh.material as MeshStandardMaterial).map = data.texture;
    }
    (data.targetMesh.material as MeshStandardMaterial).needsUpdate = true;
  }

  toEuler(rotation: Vector3): Euler {
    return new Euler().setFromVector3(rotation, 'XYZ');
  }

  toggleSceneViewMode(): void {
    this.inSceneViewMode = !this.inSceneViewMode;

    if (this.inSceneViewMode) {
      console.log('Scene view enabled!');
    } else {
      console.log('Scene view disabled!');
    }
  }

  getCameraPosition(): Vector3 {
    return this.camera.position;
  }

  getCameraRotation(): Euler {
    return this.camera.rotation;
  }

  getWorldPointFromClick(clientX: number, clientY: number): Vector3 {
    const dimensions = this.renderer.domElement
    const rayX = (clientX / dimensions.width) * 2 - 1;
    const rayY = -(clientY / dimensions.height) * 2 + 1;
    const rayVector = new Vector2(rayX, rayY);

    const raycaster = new Raycaster();
    raycaster.setFromCamera(rayVector, this.camera);

    const intersects = raycaster.intersectObjects(this.scene.children, true);

    const worldPos = new Vector3();
    if (intersects[0]) {
      worldPos.copy(intersects[0].point);
    }

    return worldPos;
  }

  onWindowResize(): void {
    if (this.camera === undefined || this.renderer === undefined) {
      return;
    }
    this.manageDisplaySize();
  }

  private resizeRendererToDisplaySize(): boolean {
    const canvas = this.renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = canvas.clientWidth * pixelRatio | 0;
    const height = canvas.clientHeight * pixelRatio | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      this.renderer.setSize(width, height, false);
    }
    return needResize;
  }

  public getCanvas(): HTMLCanvasElement
  {
    return this.renderer.domElement;
  }

  private manageDisplaySize(): void {
    if (this.resizeRendererToDisplaySize()) {
      const canvas = this.renderer.domElement;
      this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
      this.camera.fov = (canvas.clientWidth > canvas.clientHeight) ? this.landscapeFov : this.portraitFov;
      this.camera.updateProjectionMatrix();
    }
  }

  onDestroy(): void {
    this.scene?.clear();
    document.body.removeChild(this.renderer.domElement);
  }

  rotateCamera(mouseX: number) {
    if (!this.allowUserCameraPan) return;

    const canvas = this.renderer.domElement;
    let clampedMouse = Math.min(Math.max(mouseX, 0), canvas.clientWidth);
    this.targetCameraGroupRotation = new Quaternion().setFromEuler(new Euler(0, MathUtils.degToRad(MathUtils.lerp(this.rotateRangeDeg, -this.rotateRangeDeg, clampedMouse / canvas.clientWidth)), 0, 'XYZ'));
    this.startCameraGroupRotation = new Quaternion().copy(this.cameraGroup.quaternion);
    this.targetCameraGroupRotationTime = 0;
    this.cameraGroupRotationSpeed = 2;
  }

  tweenCameraGroupToZero() {
    let durationMs = 2000;
    let currentQuat = new Quaternion();
    let rot = new Quaternion().copy(this.cameraGroup.quaternion);
    let targetRot = new Quaternion().setFromEuler(new Euler(0, 0, 0));

    let currentTime = new Vector2(0, 0);
    let maxTime = new Vector2(1, 0);
    let rotationTween = new Tween(currentTime)
      .to(maxTime, durationMs)
      .easing(Easing.Quadratic.InOut)
      .onUpdate(() => {
        currentQuat.slerpQuaternions(rot, targetRot, currentTime.x);
        this.cameraGroup.quaternion.copy(currentQuat);
      });

    rotationTween.start();
  }
}

