import { ExperienceAnimation } from './experience-animation';
import { ExperiencePanelType } from './experience-panel-type';
import { Vector3 } from 'three';
import { ExperienceHotspot } from "./experience-hotspot";
import { Type } from "class-transformer";
import { ExperienceContent } from './experience-content';
import {ExperienceTextureSwap} from "./experience-texture-swap";

export class ExperiencePage {


  constructor() {
  }

  public name: string;

  public panelType: ExperiencePanelType;

  @Type(() => Vector3)
  public cameraPosition: Vector3;

  @Type(() => Vector3)
  public cameraRotation: Vector3;

  @Type(() => ExperienceAnimation)
  public animations: ExperienceAnimation[] = [];

  @Type(() => ExperiencePage)
  public children: ExperiencePage[] = [];

  @Type(() => ExperienceContent)
  public content: ExperienceContent[] = [];

  @Type(() => ExperienceContent)
  public fullPageContent: ExperienceContent[] = [];

  public useButtonNameForTagline: boolean = true;

  @Type(() => ExperienceHotspot)
  public hotspots: ExperienceHotspot[] = [];

  public showButtonsOnMobile: boolean = true;

  public hiddenModels: string[] = [];

  public showTopNavigationButtons: boolean = true;

  public showNavigationButtons: boolean = false;

  public showBreadcrumb: boolean = true;

  @Type(() => ExperienceTextureSwap)
  public textureSwaps: ExperienceTextureSwap[] = [];

  public showBottomNavigationButtonsOnMobile: boolean = false;

  public showLozengesOnMobile: boolean = false;

  public showLozengesOnDesktop: boolean = true;

  public showBigHeader: boolean = false;

  public allowUserCameraRotate: boolean = false;
}
