import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExperienceManagerService } from '../experience-manager.service';

@Component({
  selector: 'app-experience-loading',
  templateUrl: './experience-loading.component.html',
  styleUrls: ['./experience-loading.component.sass']
})
export class ExperienceLoadingComponent implements AfterViewInit, OnDestroy {

  public cssClass: string;
  public headerLoadingText: string;
  public subHeaderLoadingText: string;
  public loadingBannerImage: string;
  public percentLoaded: number = 0;
  public name: string;
  public sub: Subscription = new Subscription();

  constructor(public experienceManager: ExperienceManagerService) { }

  ngAfterViewInit(): void {
    this.percentLoaded = 0;
    this.sub.add(this.experienceManager.onLoadProgress.subscribe(x => {
      this.percentLoaded = x;
    }));
    this.sub.add(this.experienceManager.experienceName.subscribe(x => {
      setTimeout(() => {
        this.name = x;
        this.cssClass = `${this.name}-loading`;
        this.loadingBannerImage = `/assets/images/v3/content-images/${this.name}-loading.svg`;
        this.headerLoadingText = `${this.name}`;
        this.subHeaderLoadingText = `${this.name}-Home-Tagline`;
      }, 1);
    }));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  hide() {
    if (this.percentLoaded < 100) return;
    this.experienceManager.hideLoading.emit();
  }
}
