import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DefaultMissingTranslationHandler } from '../localization/default-missing-translation-handler';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LocalizationComponent } from '../localization/localization.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { I18nCountrySelectModule, I18nCountrySelectService } from "ngx-i18n-country-select";
import { CountrySelectComponent } from './country-select/country-select.component';
import { MuPipe } from './pipes/mu.pipe';

var countries = require("i18n-iso-countries");

@NgModule({
  declarations: [LocalizationComponent, CountrySelectComponent, MuPipe],
  exports: [
    FormsModule,
    TranslateModule,
    LocalizationComponent,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    QRCodeModule,
    ReactiveFormsModule,
    I18nCountrySelectModule,
    CountrySelectComponent,
    MuPipe
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: DefaultMissingTranslationHandler },
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    I18nCountrySelectModule,
    RouterModule,
    QRCodeModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    I18nCountrySelectService,
    {
      provide: APP_INITIALIZER,
      useFactory: setUpI18nCountrySelect,
      deps: [TranslateService, I18nCountrySelectService],
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  constructor(translateService: TranslateService, countrySelectService: I18nCountrySelectService) {
    const browserLang = translateService.getBrowserLang();
    console.log(`Browser Language: ${browserLang}`);
    countries.registerLocale(require(`i18n-iso-countries/langs/${browserLang.match(/en|fr|de|es|it|hu|nl|pl|pt|ru|tr/) ? browserLang : 'en'}.json`))
    translateService.use(browserLang.match(/en|fr|de|es|it|hu|nl|pl|pt|ru|tr/) ? browserLang : 'en');
    countrySelectService.use([browserLang.match(/en|fr|de|es|it|hu|nl|pl|pt|ru|tr/) ? browserLang : 'en']);
  }

}

export function setUpI18nCountrySelect(translateService: TranslateService, countrySelectService: I18nCountrySelectService) {
  return () => {
    const browserLang = translateService.getBrowserLang();
    countrySelectService.use([browserLang.match(/en|fr|de|es|it|hu|nl|pl|pt|ru|tr/) ? browserLang : 'en'])
  };
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  console.log('in createTranslateLoader');
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}
