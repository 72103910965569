import { Component, OnInit } from '@angular/core';
import { FormEndpointService } from '../form-endpoint.service';

@Component({
  selector: 'app-vf2-brochure-form',
  templateUrl: './vf2-brochure-form.component.html',
  styleUrls: ['./vf2-brochure-form.component.sass']
})
export class Vf2BrochureFormComponent implements OnInit {

  constructor(private formEndPointService: FormEndpointService) { }

  ngOnInit(): void {
    this.formEndPointService.formEndpoint.next('https://go.rolanddg.eu/l/181922/2021-06-02/rsk3g');
  }
}
