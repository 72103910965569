import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as experience from 'src/assets/v3/experiences/vg3.json';
import { ExperienceManagerService } from '../../shared/experience/experience-manager.service';
import { Experience } from '../../shared/experience/models/experience';
import { Subscription } from 'rxjs';
import { ViewportRuler } from '@angular/cdk/overlay';
import { plainToInstance } from 'class-transformer';

@Component({
  selector: 'app-vg3',
  templateUrl: './vg3.component.html',
  styleUrls: ['./vg3.component.sass']
})
export class Vg3Component implements AfterViewInit, OnDestroy {

  @ViewChild('video') video: ElementRef<HTMLVideoElement>;

  constructor(private experienceService: ExperienceManagerService) { }

  ngOnDestroy(): void {
    this.experienceService.onDestroy();
  }

  async ngAfterViewInit(): Promise<void> {
    let experienceCast = plainToInstance(Experience, experience);
    await this.experienceService.loadExperience(experienceCast, this.video.nativeElement);
  }
}
