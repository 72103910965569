<div class="no-mobile-landscape w-100 h-100">
  <app-experience-loading *ngIf="showLoading" [@fadeAnimation]="'in'"></app-experience-loading>
  <!-- TODO - below section belongs in it's own component -->
  <div class="hotspot-container" *ngIf="showUI && !showLoading">
    <div class="hotspot d-lg-flex justify-content-start align-items-center {{hotspot.className}}"
         [ngClass]="{'d-none': !hotspot.visibleOnMobile, 'd-flex': hotspot.visibleOnMobile }"
         *ngFor="let hotspot of transitioningPage?.hotspots"
         (mouseenter)="onMouseOverHotspot(hotspot, hotspotHoverText)" (mouseleave)="onMouseLeaveHotspot(hotspot)"
         [style]="getHotspotStyle(hotspot)"
         (click)="switchPageByName(hotspot.onClickGoToPage)" [@delayedFadeAnimation]="'in'">
      <div class="hotspot-hover" #hotspotHoverText>
        {{hotspot.hoverTextKey | translate}}
      </div>
    </div>
  </div>

  <div class="overlay d-flex flex-column justify-content-start align-items-center"
       *ngIf="experience !== undefined && experience.startingPage !== undefined && showUI && !showLoading">

    <!--AR Button-->
    <app-experience-augmented-reality-button #arButton [arUrl]="experience.arUrl"></app-experience-augmented-reality-button>

    <!--Navbar-->
    <nav class="navbar navbar-expand-lg w-100 py-0">
      <div class="container-fluid d-flex justify-content-start">
        <button class="navbar-toggler my-2" type="button" (click)="toggleNavbar()"></button>
        <div class="navbar-brand logo" (click)="switchPage(experience.startingPage)"></div>
        <div class="ms-auto home-button d-block d-lg-none" (click)="switchPage(experience.startingPage)"
             *ngIf="this.transitioningPage !== experience.startingPage" [@fadeAnimation]="'in'">
        </div>
        <div class="d-none d-sm-block d-lg-none" [ngClass]="{'ms-auto': this.transitioningPage === experience.startingPage, 'ms-3': this.transitioningPage !== experience.startingPage}">
          <a class="contact-us py-2" target="_blank" href="{{experience.contactUsLinkKey | translate}}">{{experience.contactUsTextKey | translate}}</a>
        </div>
        <div class="collapse navbar-collapse h-100" [ngbCollapse]="!navbarExpanded">
          <ul class="navbar-nav d-flex justify-content-lg-center align-items-lg-center pb-2 pb-lg-0 w-100 mb-5 mb-lg-0">

            <li class="ms-0 py-1 py-lg-0 px-1 ms-lg-auto nav-item d-block d-sm-none">
              <a class="contact-us-link" target="_blank" href="{{experience.contactUsLinkKey| translate}}">{{experience.contactUsTextKey | translate}}</a>
            </li>

            <li class="nav-item py-1 py-lg-0 d-block d-md-none">
              <button class="mx-0 mx-xl-2" (click)="showAr()">
                {{'ViewInMySpace' | translate}}
              </button>
            </li>

            <li class="ms-0 py-1 ms-lg-auto nav-item d-flex flex-column justify-content-start align-items-start d-lg-none" id="language-selector-mobile" ngbDropdown>
              <button class="mx-0 mx-xl-2 language-selector"
                      ngbDropdownToggle><span>{{currentLanguage}}</span></button>
              <div ngbDropdownMenu aria-labelledby="language-selector-mobile" class="language-selector-container pb-0  flex-row flex-lg-column">
                <button ngbDropdownItem class="language-selector-menu-item"
                        *ngFor="let supportedLanguage of supportedLanguages"
                        (click)="changeLanguage(supportedLanguage)">{{supportedLanguage}}</button>
              </div>
            </li>

            <app-experience-nav-item *ngFor="let navPage of experience.startingPage.children" [page]="navPage" [currentPage]="transitioningPage" class="ms-lg-auto"></app-experience-nav-item>

            <li class="ms-0 py-1 ms-lg-auto nav-item d-none d-lg-flex" id="language-selector" ngbDropdown>
              <button class="mx-0 mx-xl-2 language-selector"
                      ngbDropdownToggle><span>{{currentLanguage}}</span></button>
              <div ngbDropdownMenu aria-labelledby="language-selector" class="language-selector-container mt-lg-3 pb-0 flex-row flex-lg-column">
                <button ngbDropdownItem class="language-selector-menu-item"
                        *ngFor="let supportedLanguage of supportedLanguages"
                        (click)="changeLanguage(supportedLanguage)">{{supportedLanguage}}</button>
              </div>
            </li>
          </ul>
          <div class="ms-auto d-none d-lg-block">
            <a class="contact-us py-2" target="_blank" href="{{experience.contactUsLinkKey| translate}}">{{experience.contactUsTextKey | translate}}</a>
          </div>
        </div>

      </div>
    </nav>

    <!--Top Content-->
    <div class="main-panel container-fluid d-flex flex-column mt-5" [@fadeAnimation]="'in'"
         [ngClass]="{ 'justify-content-start': isLeft(transitioningPage), 'justify-content-end': isRight(transitioningPage), 'justify-content-center': isCenterOrNone(transitioningPage), 'text-center' : isCenterOrNone(transitioningPage), 'align-items-center': isCenterOrNone(transitioningPage) }"
         *ngIf="transitioningPage !== undefined">
      <div class="breadcrumb d-flex" *ngIf="transitioningPage.showBreadcrumb">
        <div class="breadcrumb-item breadcrumb-product-name" (click)="switchPage(experience.startingPage)">
          {{this.experience.name | translate}}
        </div>
        <div class="breadcrumb-item" *ngFor="let breadCrumb of experienceBreadcrumbs" (click)="switchPage(breadCrumb)">
          {{breadCrumb.name | translate}}
        </div>
      </div>
      <h1 class="mb-0 big-header"
          *ngIf="transitioningPage.showBigHeader">{{getBigHeaderKey(transitioningPage) | translate}}</h1>
      <h1 class="mb-0">{{getTaglineKey(transitioningPage) | translate}}</h1>
      <h4 class="mb-2">{{getSubTaglineKey(transitioningPage) | translate}}</h4>
      <div class="blue-bar w-100 mb-2"></div>
      <div class="main-buttons flex-row flex-wrap w-100 align-items-center justify-content-center"
           [ngClass]="{ 'justify-content-md-start': !isCenterOrNone(transitioningPage), 'justify-content-md-center': isCenterOrNone(transitioningPage), 'd-none': !transitioningPage.showLozengesOnMobile, 'd-flex': transitioningPage.showButtonsOnMobile, 'd-md-none': !transitioningPage.showLozengesOnDesktop, 'd-md-flex': transitioningPage.showLozengesOnDesktop }">
        <button class="btn me-2 mb-2" [ngClass]="{ 'active' : subPage === transitioningPage }"
                *ngFor="let subPage of parentPage.children"
                (click)="switchPage(subPage)">{{subPage.name | translate}}</button>
      </div>
    </div>

    <!--Panel Content-->
    <div class="position-relative w-100">
      <div class="container-fluid position-absolute position-md-relative mt-md-3 mt-0 main-content"
           *ngIf="hasContent(currentPage)"
           [ngClass]="{'hide-content': isContentPanelCollapsed}">
        <div class="row justify-content-center"
             [ngClass]="{ 'justify-content-md-start': isLeft(currentPage), 'justify-content-md-end': !isLeft(currentPage) }">
          <div class="col-12 col-lg-5 position-relative">
            <div class="content-panel d-flex flex-column" *ngIf="panelFade" [@slideDownAnimation]="'in'">
              <button class="content-panel-button-swipe d-lg-none d-block"
                      *ngIf="this.transitioningPage !== experience.startingPage"
                      [ngClass]="{ 'content-panel-button-swipe-back': hasContent(getParentPage(transitioningPage)) }"
                      (click)="switchPage(getParentPage(transitioningPage))"></button>
              <!--            <button class="content-panel-button-swipe d-lg-none d-block" *ngIf="panelFade"-->
              <!--                    (click)="toggleContent()"></button>-->
              <app-experience-content *ngFor="let content of currentPage.content" [currentPage]="currentPage"
                                      [content]="content"></app-experience-content>
              <div class="content-panel-navigation mt-auto d-sm-flex flex-row pt-3"
                   [ngClass]="{ 'd-none': !currentPage.showBottomNavigationButtonsOnMobile, 'd-flex': currentPage.showBottomNavigationButtonsOnMobile } "
                   *ngIf="currentPage.showNavigationButtons">
                <button class="btn content-panel-navigation-left-arrow content-panel-navigation-arrow"
                        (click)="switchPreviousPage()"></button>
                <div
                  class="content-panel-navigation-page-button-container mx-auto d-flex flex-row align-items-center justify-content-center">
                  <button *ngFor="let page of parentPage.children" class="content-panel-navigation-page-button"
                          [ngClass]="{ 'active': page === currentPage }" (click)="switchPage(page)"></button>
                </div>
                <button class="btn content-panel-navigation-right-arrow content-panel-navigation-arrow"
                        (click)="switchNextPage()"></button>
              </div>
            </div>
          </div>
          <div class="full-content-panel d-none d-lg-flex flex-column justify-content-around col-12 col-lg-7" *ngIf="panelFade && currentPage.fullPageContent.length > 0" [@delayedFadeAnimation]="'in'">
            <app-experience-content *ngFor="let content of currentPage.fullPageContent" [currentPage]="currentPage"
                                    [content]="content"></app-experience-content>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Debug Mode-->
  <!-- TODO - below section belongs in it's own component -->
  <div class="sceneViewLabels" *ngIf="sceneService.inSceneViewMode && showUI && !showLoading">

    <div class="row">
      <label class="col-3 col-form-label">Position</label>
      <div class="col-9">
        <input type="text" class="form-control" [value]="getSceneViewCameraPosition()"/>
      </div>
    </div>
    <div class="row">
      <label class="col-3 col-form-label">Rotation</label>
      <div class="col-9">
        <input type="text" class="form-control" [value]="getSceneViewCameraRotation()"/>
      </div>
    </div>
    <div class="row">
      <label class="col-3 col-form-label">Hotspot Position</label>
      <div class="col-9">
        <input type="text" class="form-control" [value]="worldPos"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        Move the camera with WASD<br/>
        Rotate the camera with Q & E<br/>
        Pan the camera with the arrow keys<br/>
        Press 'u' to Toggle UI<br/>
        Press left click and press 'h' to get a hotspot<br/>
        Press 'j' to reset camera rotation<br/>
      </div>
    </div>
  </div>
</div>

<div class="mobile-landscape-only screen-rotate-bg d-flex flex-column justify-content-center align-items-center">
  <nav class="navbar w-100 fixed-top">
    <div class="container-fluid d-flex justify-content-start">
      <div class="navbar-brand logo" (click)="switchPage(experience.startingPage)"></div>
    </div>
  </nav>
  <div class="screen-rotate"></div>
</div>
